import Controller from '@ember/controller';
import { service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AnalyticsOccupancyIndexController extends Controller {
  @service localStorage;

  @tracked loginRequired = false;
  @service state;
  @tracked planData = {
    visitors: this.state.vrSubscription.plan,
    desks: this.state.desksSubscription,
    rooms: this.state.roomsSubscription,
  };
  @service flashMessages;
  @service messageBus;
  @tracked showModal = false;
  @tracked modalIframeUrl;
  @tracked showCopyLinkModal = false;

  constructor() {
    super(...arguments);
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
  }

  @action
  handleMessage(message) {
    const {
      currentCompany: { id: companyId },
      currentLocation: { id: locationId },
    } = this.state;
    if (message.event === 'showOccupancyGlobalSRModal') {
      this.modalIframeUrl = urlBuilder.embeddedOccupancyLocationReportsModalUrl(
        companyId,
        locationId,
        this.loginRequired,
      );
      this.showModal = true;
    } else if (message.event === 'closeOccupancyLocationSRModal') {
      this.showModal = false;
    } else if (message.event === 'showToaster') {
      const type = message.type;
      const msg = message.msg;
      this.flashMessages.showAndHideFlash(type, msg);
    } else if (message.event === 'showOccupancyCopyLinkModal') {
      let copyUrl = window.location.href;
      if (message.location_id) {
        copyUrl += `?location_id=${message.location_id}`;
      }
      this.modalIframeUrl = urlBuilder.embeddedOccupancyCopyLinkModal(
        copyUrl,
        this.loginRequired,
        companyId,
        locationId,
      );
      this.showCopyLinkModal = true;
    } else if (message.event === 'closeOccupancyCopyLinkModal') {
      this.showCopyLinkModal = false;
    }
  }

  /**
   * @return {String}
   */
  get iframeUrl() {
    const {
      currentCompany: { id: companyId },
      currentLocation: { id: locationId },
    } = this.state;
    return urlBuilder.embeddedOccupancyLocationAnalyticsUrl(companyId, locationId, this.loginRequired);
  }

  @action
  trackLoggedIn() {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}
