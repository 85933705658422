import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import VirtualFrontDeskLandingPageRoute from 'garaje/pods/virtual-front-desk/landing-page/route';
import type StateService from 'garaje/services/state';

export default class VirtualFrontDeskGlobalViewLandingPageRoute extends VirtualFrontDeskLandingPageRoute {
  controllerName = 'virtual-front-desk/landing-page';
  templateName = 'virtual-front-desk/landing-page';

  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare abilities: AbilitiesService;

  // eslint-disable-next-line @typescript-eslint/require-await
  async beforeModel(): Promise<void> {
    if (
      (this.state.vfdSubscription?.canAccessVirtualFrontDesk ||
        this.state.visitorsSubscription?.canAccessVirtualFrontDesk) &&
      this.abilities.can('visit virtual-front-desk')
    ) {
      void this.router.transitionTo('location-overview.virtual-front-desk.locations');
    }
  }
}
