import ActiveStorage from '@algonauti/ember-active-storage/services/active-storage';
import { service } from '@ember/service';
import type { CookiesService } from 'ember-cookies/services/cookies';
import config from 'garaje/config/environment';

// Extending ActiveStorage addon for additional headers. See addon docs:
// https://github.com/algonauti/ember-active-storage#sending-authentication-headers
export default class ActiveStorageService extends ActiveStorage {
  declare upload: (
    file: File,
    url: string,
    options: { onProgress: (progress: number) => void },
  ) => Promise<{ signedId: string }>;

  @service declare cookies: CookiesService;

  get headers(): Record<string, string> {
    const hash: Record<string, string> = {};

    const csrfToken = this.cookies.read('csrf_token');
    const xRequestId = this.cookies.read('X-Request-ID');

    if (csrfToken) {
      hash['Authorization'] = '';
      hash['X-CSRF-Token'] = csrfToken;
    }

    hash['X-Forwarded-Host'] = config.hostName;

    if (xRequestId) {
      hash['X-Request-ID'] = xRequestId;
    }

    return hash;
  }
}
