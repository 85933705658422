import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type InfinityService from 'ember-infinity/services/infinity';
import ExtendedInfinityModel from 'garaje/infinity-models/v3-offset';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type ConnectFloorModel from 'garaje/models/connect-floor';
import type ZoneModel from 'garaje/models/zone';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type PropertySettingsSuitesController from './controller';

interface ColumnHeader {
  /**
   * the internal attribute name to sort by
   */
  field: string;
  /**
   * how the header should display
   */
  label: string;
  /**
   * value used for sorting in ascending order (e.g., "A-Z")
   */
  sortAsc: string;
  /**
   * value used for sorting in descending order (e.g., "Z-A")
   */
  sortDesc: string;
}

export interface SuitesRouteModel {
  columnHeaders: ColumnHeader[];
  /**
   * Only populated when "connect-floors" feature flag is enabled.
   */
  floors: RecordArray<ConnectFloorModel> | null;
  property: ZoneModel;
  suites: PaginatedRecordArray<ZoneModel>;
}

interface PropertySettingsSuitesRouteParams {
  sortBy?: string;
  sortOrder?: string;
}

// Field to sort by if none (or an invalid one) was specified
const DEFAULT_SORT_FIELD = 'name';

export default class PropertySettingsSuitesRoute extends Route {
  @service declare featureFlags: FeatureFlagsService;
  @service declare infinity: InfinityService;
  @service declare store: Store;

  queryParams = {
    sortBy: { refreshModel: true },
    sortOrder: { refreshModel: true },
  };

  async model(params: PropertySettingsSuitesRouteParams): Promise<SuitesRouteModel> {
    const property = <ZoneModel>this.modelFor('property');

    let sortParam = DEFAULT_SORT_FIELD;

    const sortColumn = this.#tableColumnHeaders.find((header) => header.label === params.sortBy);
    if (sortColumn) {
      const sortFlag = params.sortOrder === sortColumn.sortDesc ? '-' : '';
      sortParam = `${sortFlag}${sortColumn.field}`;
    }

    let floors = null;
    if (this.featureFlags.isEnabled('connect-floors')) {
      floors = this.store.query('connect-floor', {
        filter: {
          property: property.id,
        },
      });
    }

    return hash({
      columnHeaders: this.#tableColumnHeaders,
      floors,
      property,
      suites: this.infinity.model(
        'zone',
        {
          include: this.featureFlags.isEnabled('connect-floors') ? 'floor,tenants' : 'tenants',
          filter: {
            parent: property.id,
          },
          sort: sortParam,
          perPage: 20,
          perPageParam: 'page[limit]',
          pageParam: 'page[offset]',
          startingPage: 0,
          countParam: 'meta.total',
        },
        ExtendedInfinityModel,
      ),
    });
  }

  setupController(controller: PropertySettingsSuitesController, model: SuitesRouteModel, transition: Transition): void {
    super.setupController(controller, model, transition);
    controller.total = model.suites.meta.total;
  }

  resetController(controller: PropertySettingsSuitesController, isExiting: boolean): void {
    if (isExiting) {
      controller.backTo = null;
    }
  }

  // Build the list of columns that can appear in the table. We define this here to have a single spot
  // to reference for all the data about the table, such as what it's ascending/descending options are.
  // This is done as a private method because the "area" and "floor" columns are conditional based on
  // feature flags, so we need to be able to query the `featureFlags` service. When there are no
  // conditional columns, this could be moved to a static array outside of the route class.
  get #tableColumnHeaders(): ColumnHeader[] {
    const headers = [
      { label: 'Suite name', sortAsc: 'A-Z', sortDesc: 'Z-A', field: 'name' },
      {
        label: 'Area (sq. ft.)',
        sortAsc: 'Smallest–Largest',
        sortDesc: 'Largest–Smallest',
        field: 'area',
      },
    ];

    if (this.featureFlags.isEnabled('connect-floors')) {
      headers.push({
        label: 'Floor',
        sortAsc: 'Bottom floor–top floor',
        sortDesc: 'Top floor–bottom floor',
        field: 'floor.position',
      });
    }
    return headers;
  }
}
