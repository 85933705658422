import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type NeighborhoodModel from 'garaje/models/neighborhood';
import type StateService from 'garaje/services/state';

interface NeighborhoodFormArgs {
  neighborhoodId: number;
  onClose: () => void;
}

export default class NeighborhoodForm extends Component<NeighborhoodFormArgs> {
  @service declare state: StateService;
  @service declare store: StoreService;

  @tracked showColorPicker = false;
  @tracked selectedColor = this.neighborhood.displayColor;

  get neighborhood(): NeighborhoodModel {
    return this.store.peekRecord('neighborhood', this.args.neighborhoodId) as NeighborhoodModel;
  }

  get backgroundColor(): string {
    return 'background-color: ' + this.neighborhood.displayColor;
  }

  @action
  onColorSelect(color: string): void {
    set(this.neighborhood, 'displayColor', color);
  }

  @action
  getFloorName(floorId: number): string | undefined {
    const floor = this.store.peekRecord('map-floor', floorId);
    return floor?.name;
  }
}
