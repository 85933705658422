import type AnnouncementTemplateModel from 'garaje/models/announcement-template';

export enum App {
  VISITORS = 'visitors',
  DELIVERIES = 'deliveries',
  ROOMS = 'rooms',
  DESKS = 'desks',
  WORKPLACE = 'empxp',
  CONNECT = 'connect',
  EMNO = 'emno',
  VFD = 'vfd',
}

export const APP = App;

export const ACCESS_CONTROL_INTEGRATIONS = Object.freeze({
  CISCO_MERAKI: { pluginId: '71a29345-0d89-4d3d-b7fb-758efcf99bf9', appStorePath: 'cisco-meraki' },
  BRIVO: { pluginId: 'd0e656d4-7bfc-4863-9888-1927b5dcbf94', appStorePath: `brivo` },
  KISI: { pluginId: '2797438f-226e-450b-8c2d-cb89692eb29c', appStorePath: `kisi` },
  AVIGILON_ALTA: { pluginId: '0ab9eb39-72e7-4df4-90db-1e5684c47794', appStorePath: `avigilon-alta` },
});

export const ITEM = Object.freeze({
  VISITORS: 'location',
  DELIVERIES: 'delivery area',
  ROOMS: 'room',
  DESKS: 'desk location',
});

export const PLAN_LEVEL = Object.freeze({
  BASIC: 'basic',
  STANDARD: 'standard',
  PREMIUM: 'premium',
  PREMIUM_PLUS: 'premium-plus',
  ENTERPRISE: 'enterprise',
});

export const RANK = Object.freeze({
  BASIC: 0,
  STANDARD: 10,
  PREMIUM: 20,
  ENTERPRISE: 30,
});

export const PERIOD = Object.freeze({
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
});

export const START_SUBSCRIPTION_PLAN_ID = Object.freeze({
  DELIVERIES: 'deliveries-basic-monthly-calendar-billing',
  ROOMS: 'rooms-standard-monthly-calendar-billing',
  VISITORS: 'visitors-premium-monthly-calendar-billing',
});

export const SUBSCRIPTION_STATUS = Object.freeze({
  ACTIVE: 'active',
  IN_TRIAL: 'in_trial',
  CANCELLED: 'cancelled',
  PAUSED: 'paused',
  NON_RENEWING: 'non_renewing',
});

export enum FlowType {
  GLOBAL_CHILD = 'Flows::GlobalChild',
  EMPLOYEE_SCREENING = 'Flows::EmployeeScreening',
  PROPERTY = 'Flows::Property',
  PROPERTY_WALKUP = 'Flows::PropertyWalkUp',
  VISITOR_SCREENING = '',
}

export const FLOW_TYPE = FlowType;

export const NON_ASSIGNABLE_FLOWS = Object.freeze([FLOW_TYPE.EMPLOYEE_SCREENING, FLOW_TYPE.PROPERTY_WALKUP]);

export type NonAssignableFlow = (typeof NON_ASSIGNABLE_FLOWS)[number];

export const NON_POINTS_OF_INTEREST = Object.freeze(['desk', 'room', 'visitor-area', 'delivery-area']);

export const DEFAULT_FLOW_NAME = Object.freeze({
  EMPLOYEE_SCREENING: 'Employee registration',
  VISITOR_SCREENING: 'Visitors',
  EMPLOYEES_EXPECTED: 'Employees expected',
});

export const SIGN_IN_FIELD_KINDS = Object.freeze({
  SINGLE_SELECTION: 'single-selection',
  TEXT: 'text',
  EMAIL: 'email',
  PHONE: 'phone',
});

export const IFRAME_STATE = Object.freeze({
  LOADING: 'loading',
  LOADED: 'loaded',
  TIMEOUT: 'timeout',
});

export const TRUTHY = Object.freeze({
  YES: 'Yes',
  NO: 'No',
});

export const GROUP_OPERATION_OPTIONS = Object.freeze({
  AND: 'and',
  OR: 'or',
});

export const RULE_OPERATION_OPTIONS = Object.freeze({
  IS: 'is',
  IS_NOT: 'is not',
});

export const RULE_ACTION_OPTIONS = Object.freeze({
  DENY: 'deny_entry',
  ALERT: 'alert',
  REQUIRE_APPROVAL: 'require_approval',
});

export const DISCARDED_RESPONSE_STRINGS = Object.freeze({
  PLACEHOLDER: '::RESPONSE_DISCARDED::',
  DISPLAY_TEXT: 'Response discarded',
});

export const STORE_RESPONSE_CONFIG_OPTIONS = Object.freeze({
  STORED: 'stored',
  DISCARDED: 'discarded',
  MANAGED: 'managed',
});

export const NEIGHBORHOOD_SCIM_CATEGORIES = Object.freeze({
  DEPARTMENTS: 'Departments',
  DIVISIONS: 'Divisions',
  ORGANIZATIONS: 'Organizations',
  GROUPS: 'Groups',
});

export const PURPOSE_OF_VISIT = Object.freeze({
  EMPLOYEE_REGISTRATION: 'Employee registration',
});

export const BANNERS = Object.freeze({
  UPGRADE_REQUIRED: 'upgrade-required',
});

export const LANGUAGE_SUPPORT = Object.freeze({
  DEFAULT: 'en',
});

export const DESK_AVAILABILITY = Object.freeze({
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
  USER_ASSIGNED: 'userAssigned',
});

export const APPROVAL_STATUS = Object.freeze({
  APPROVED: 'approved',
  DENIED: 'denied',
  PENDING: 'pending',
  REVIEW: 'review',
});

export const INVITE_STATE = Object.freeze({
  APPROVED: 'approved',
  DENIED: 'denied',
  PAST_WINDOW: 'pastWindow',
  SIGNED_IN: 'signedIn',
  SCHEDULED: 'scheduled',
  UNSCHEDULED: 'unscheduled',
  PENDING: 'pending',
});

export const DESK_COUNT = Object.freeze({
  PER_LICENSE: 25,
  WARNING_THRESHOLD: 6,
});

export const EMPLOYEE_COUNT = Object.freeze({
  EMPLOYEE_LIMIT: 50,
});

export const IMPRESSION_NAMES = Object.freeze({
  CHILI_PIPER_MEETING_URL_OPENED: 'chili-piper-meeting-redirect-opened',
  CHILI_PIPER_MODAL_OPENED: 'chili-piper-modal-opened',
  EMPLOYEE_GATING: 'employee-gating',
  LOCATION_GATING_MODAL_DEACTIVATE_LOCATIONS_CLICKED: Object.freeze({
    BASIC: 'location_gating_modal_deactivate_locations_clicked_on_basic',
    PREMIUM: 'location_gating_modal_deactivate_locations_clicked_on_upgrade_to_premium',
    STANDARD: 'location_gating_modal_deactivate_locations_clicked_on_upgrade_to_standard',
  }),
  LOCATION_GATING_MODAL_DEACTIVATE_LOCATIONS_SHOWN: Object.freeze({
    BASIC: 'location_gating_modal_deactivate_locations_shown_on_basic',
    PREMIUM: 'location_gating_modal_deactivate_locations_shown_on_premium',
    STANDARD: 'location_gating_modal_deactivate_locations_shown_on_standard',
  }),
  LOCATION_GATING_MODAL_DEACTIVATE_LOCATIONS_SHOWN_ON_UPGRADE: Object.freeze({
    PREMIUM: 'location_gating_modal_deactivate_locations_shown_on_upgrade_to_premium',
    STANDARD: 'location_gating_modal_deactivate_locations_shown_on_upgrade_to_standard',
  }),
  LOCATION_GATING_MODAL_GET_MORE_LOCATIONS_CLICKED: Object.freeze({
    BASIC: 'location_gating_modal_get_more_locations_from_basic_clicked',
    PREMIUM: 'location_gating_modal_get_more_locations_from_premium_clicked',
    STANDARD: 'location_gating_modal_get_more_locations_from_standard_clicked',
  }),
  LOCATION_GATING_MODAL_SHOWN: Object.freeze({
    BASIC: 'location_gating_modal_shown_on_basic',
    NON_ADMIN: 'location_gating_modal_shown_on_non_admin',
    PREMIUM: 'location_gating_modal_shown_on_premium',
    STANDARD: 'location_gating_modal_shown_on_standard',
  }),
  LOCATION_GATING_MODAL_SHOWN_ON_UPGRADE: Object.freeze({
    NON_ADMIN: 'location_gating_modal_shown_on_non_admin',
    PREMIUM: 'location_gating_modal_shown_on_upgrade_to_premium',
    STANDARD: 'location_gating_modal_shown_on_upgrade_to_standard',
  }),
  LOCATION_GATING_MODAL_UPGRADE_CLICKED: Object.freeze({
    BASIC: 'location_gating_modal_upgrade_from_basic_clicked',
    PREMIUM: 'location_gating_modal_upgrade_to_premium_clicked',
    STANDARD: 'location_gating_modal_upgrade_to_standard_clicked',
  }),
  PREMIUM_FEATURE_GATING_MODAL_SHOWN: 'premium-feature-gating-modal-shown',
  VISITORS_BASIC_TO_PREMIUM_TRIAL: 'visitors-basic-to-premium-trial',
  VISITORS_ONBOARDING_FIRST_VIEW: 'visitors-onboarding-first-view',
  VISITORS_ONBOARDING_IPAD_NO: 'visitors-onboarding-ipad-no',
  VISITORS_ONBOARDING_IPAD_YES: 'visitors-onboarding-ipad-yes',
  VISITORS_ONBOARDING_VIDEO_WALKTHROUGH_CLOSED: 'visitors-onboarding-video-walkthrough-closed',
  VISITORS_COUPON_OFFER_MODAL_CLICKED: 'visitors-coupon-offer-modal-clicked',
  VISITORS_COUPON_OFFER_MODAL_CLOSED: 'visitors-coupon-offer-modal-closed',
  VISITORS_COUPON_OFFER_MODAL_VIEWED: 'visitors-coupon-offer-modal-viewed',
  VISITORS_TRIAL_END_MODAL: Object.freeze({
    VIEWED: 'visitors-trial-end-modal-viewed',
    CLOSED: 'visitors-trial-end-modal-closed',
    BASIC: 'visitors-trial-end-modal-clicked-on-basic',
    STANDARD: 'visitors-trial-end-modal-clicked-on-standard',
    PREMIUM: 'visitors-trial-end-modal-clicked-on-premium',
  }),
  WORKPLACE_TRIAL_END_MODAL: Object.freeze({
    VIEWED: 'workplace_trial_end_modal_viewed',
    CLOSED: 'workplace_trial_end_close_clicked',
    STANDARD: 'workplace_trial_end_standard_clicked',
    PREMIUM: 'workplace_trial_end_premium_clicked',
  }),
  WORKPLACE_FREE_BUNDLE_BANNER_DISMISSED: 'workplace-free-bundle-banner-dismissed',
  NAVATTIC_IPAD_DEMO_VIEWED: 'navattic-ipad-demo-viewed',
  CONTACT_BDR_MODAL_SHOWN: 'contact-bdr-modal-shown',
  CONTACT_BDR_MODAL_SHOWN_BASIC: 'contact-bdr-modal-shown-on-basic',
  BILLING_CHANGE_PLANS_PAGE_VIEWED: 'billing-change-plans-page-viewed',
  BILLING_PLANS_PAGE_VIEWED: 'billing-plans-page-viewed',
  ADD_CREDIT_CARD_CLICKED: 'add-credit-card-clicked',
  SIGNUP_SURVEY_VIEWED: 'signup-survey-viewed',
  SIGNUP_SURVEY_CLOSED: 'signup-survey-closed',
  SIGNUP_SURVEY_SUBMITTED: 'signup-survey-submitted',
  IPAD_ALERTS_CONFIG_UPDATED: 'ipad-alerts-config-updated',
  PRINTER_ALERTS_CONFIG_UPDATED: 'printer-alerts-config-updated',
  VISTORS_BUY_NOW: Object.freeze({
    CTA_VIEWED: 'visitors-buy-now-cta-viewed',
    CTA_CLICKED: 'visitors-buy-now-cta-clicked',
    MODAL_CLOSED: 'visitors-buy-now-modal-closed',
    STANDARD_CLICKED: 'visitors-buy-now-modal-standard-clicked',
    PREMIUM_CLICKED: 'visitors-buy-now-modal-premium-clicked',
  }),
  EMERGENCY_NOTIFICATIONS: Object.freeze({
    CONFIGURE_EMERGENCY_NOTIFICATIONS_CLICKED: 'emno-configure-emno-clicked',
    CONFIGURE_EMERGENCY_NOTIFICATIONS_DISMISSED: 'emno-configure-emno-dismissed',
    PHONE_NUMBER_BANNER_CLICKED: 'emno-configure-emno-clicked',
    PHONE_NUMBER_BANNER_DISMISSED: 'emno-configure-emno-dismissed',
  }),
  DELIVERIES: Object.freeze({
    ADD_DELIVERY_AREA_CLICKED: 'add-delivery-area-clicked',
    DELIVERIES_LOG_VISITED: 'deliveries-log-visited',
  }),
  DASHBOARD: Object.freeze({
    HERO_CLOSED: 'homepage-hero-closed',
  }),
  MAP: Object.freeze({
    DESK_AUTO_GENERATION_ANNOUNCEMENT_BANNER_DISMISSED: 'desk-auto-generation-announcement-banner-dismissed',
  }),
  KIOSK_DEMO_IPAD_SIGNIN: 'kiosk-demo-signin-completed',
  HOST_OPTIONS_EMAIL_NOTIFICATION: Object.freeze({
    ENABLED: 'email-notification-enabled',
    DISABLED: 'email-notification-disabled',
  }),
  HOST_OPTIONS_SMS_NOTIFICATION: Object.freeze({
    ENABLED: 'sms-notification-enabled',
    DISABLED: 'sms-notification-disabled',
  }),
  HOST_OPTIONS_HOST_NOTIFICATIONS: Object.freeze({
    ENABLED: 'host-notifications-enabled',
    DISABLED: 'host-notifications-disabled',
  }),
  HOST_OPTIONS_RECEPTIONIST_NOTIFICATION: Object.freeze({
    ENABLED: 'notify-receptionists-on-host-reply-enabled',
    DISABLED: 'notify-receptionists-on-host-reply-disabled',
  }),
  SECURITY_VR_SCREENING_OPTIONS_BLOCK_LIST: Object.freeze({
    ENABLED: 'blacklist-enabled',
    DISABLED: 'blacklist-disabled',
  }),
  SECURITY_VR_SCREENING_OPTIONS_VISUAL_COMPLIANCE: Object.freeze({
    ENABLED: 'visual-compliance-enabled',
    DISABLED: 'visual-compliance-disabled',
  }),
  VR_WELCOME_SCREEN_MULTIPLE_LANGUAGES: Object.freeze({
    ENABLED: 'multiple-languages-enabled',
    DISABLED: 'multiple-languages-disabled',
  }),
  VR_INVITES_PRE_REGISTRATION: Object.freeze({
    ENABLED: 'pre-registration-enabled',
    DISABLED: 'pre-registration-disabled',
  }),
  VR_INVITES_INVITE_NOTIFICATION: Object.freeze({
    ENABLED: 'cc-receptionist-enabled',
    DISABLED: 'cc-receptionist-disabled',
  }),
  VR_INVITES_NEAR_VISIT_REGISTRATION: Object.freeze({
    ENABLED: 'near-visit-screening-enabled',
    DISABLED: 'near-visit-screening-disabled',
  }),
  VR_INVITES_PRE_REGISTRATION_REQUIRED: Object.freeze({
    ENABLED: 'pre-registration-required-enabled',
    DISABLED: 'pre-registration-required-disabled',
  }),
  VR_INVITES_TOUCHLESS_SIGN_IN: Object.freeze({
    ENABLED: 'touchless-signin-enabled',
    DISABLED: 'touchless-signin-disabled',
  }),
  VR_INVITES_SECURITY_DESK_LINK: Object.freeze({
    ENABLED: 'security-desk-link-enabled',
    DISABLED: 'security-desk-link-disabled',
  }),
  VR_EMAILS_WELCOME_GUIDE: Object.freeze({
    ENABLED: 'visitor-guide-enabled',
    DISABLED: 'visitor-guide-disabled',
  }),
  VR_EMAILS_SURVEY_EMAIL: Object.freeze({
    ENABLED: 'visitor-survey-enabled',
    DISABLED: 'visitor-survey-disabled',
  }),
  VR_NOTIFICATIONS_FALLBACK_NOTIFICATION: Object.freeze({
    ENABLED: 'fallback-notifications-enabled',
    DISABLED: 'fallback-notifications-disabled',
  }),
  VR_NOTIFICATIONS_DEVICE_IPAD_STATUS_EMAIL: Object.freeze({
    ENABLED: 'device-notifications-enabled',
    DISABLED: 'device-notifications-disabled',
  }),
  VR_NOTIFICATIONS_DEVICE_PRINTER_STATUS_EMAIL: Object.freeze({
    ENABLED: 'printer-notifications-enabled',
    DISABLED: 'printer-notifications-disabled',
  }),
});

export const SETUP_NAMES = Object.freeze({
  SIGNUP_SURVEY_COMPLETED: 'signup-survey-completed',
});

export const TenantConnectionStatus = Object.freeze({
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  INACTIVE: 'inactive',
  PENDING: 'pending',
});

export const BlocklistFilterSource = Object.freeze({
  LOCATION: 'Location',
  PROPERTY: 'Envoy::MultiTenancy::Zone',
});

export const CsvUploadDestination = Object.freeze({
  BLOCKLIST_FILTERS: 'blacklist_filters',
});

export const CsvUploadStatus = Object.freeze({
  DONE: 'done',
  IN_PROGRESS: 'in_progress',
});

export const getEnum = (
  key: 'APP' | 'PLAN_LEVEL' | 'PLAN_RANK' | 'PLAN_PERIOD' | 'START_SUBSCRIPTION_PLAN_ID' | 'SUBSCRIPTION_STATUS',
): unknown => {
  switch (key) {
    case 'APP':
      return APP;
    case 'PLAN_LEVEL':
      return PLAN_LEVEL;
    case 'PLAN_RANK':
      return RANK;
    case 'PLAN_PERIOD':
      return PERIOD;
    case 'START_SUBSCRIPTION_PLAN_ID':
      return START_SUBSCRIPTION_PLAN_ID;
    case 'SUBSCRIPTION_STATUS':
      return SUBSCRIPTION_STATUS;
  }
};

export const SECONDS_IN_A_DAY = 86400;
export const MINUTES_IN_A_WEEK = 10080;
export const MINUTES_IN_A_DAY = 1440;

export const SLACK_V2_PLUGIN_KEY = '7828c68d-b570-4c82-9f94-149c29b659ad';
export const MSTEAMS_V2_PLUGIN_KEY = '76dfe3b3-bda0-4781-9469-fadeb6fe3f56';

export const EXAMPLE_ANNOUNCEMENT_TEMPLATES: Partial<AnnouncementTemplateModel>[] = [
  {
    id: 'fire-emergency',
    name: 'Fire emergency',
    title: 'Fire Emergency Notification',
    message: `Fire emergency at [Location]. Evacuate immediately using the nearest exit. Stay clear of the building and await further instructions.`,
  },
  {
    id: 'severe-weather',
    name: 'Severe weather',
    title: 'Severe Weather Alert',
    message: `Severe weather warning: [Type of Weather] expected at [Location]. Seek shelter immediately. Follow safety protocols. Office may close early. Stay tuned for updates.`,
  },
  {
    id: 'medical-emergency',
    name: 'Medical emergency',
    title: 'Medical Emergency Alert',
    message: `Medical emergency at [Location]. Please remain calm. First responders are en route. Avoid the area to allow emergency personnel to work. Await further instructions.`,
  },
  {
    id: 'violent-incident',
    name: 'Violent incident',
    title: 'Violent Incident Alert',
    message: `Violent incident reported at [Location]. Lockdown immediately. Hide in a secure place. Silence phones. Call 911 if safe. Await further instructions.`,
  },
  {
    id: 'workplace-hazard',
    name: 'Workplace hazard',
    title: 'Workplace Hazard Notification',
    message: `Hazard reported at [Location]. Avoid the area and follow safety protocols. Do not use [specific equipment/area] until further notice. Safety team is assessing the situation. Await further instructions.`,
  },
  {
    id: 'it-system-outage',
    name: 'IT system outage',
    title: 'IT System Outage Notification',
    message: `Critical IT system outage. [System] is currently down. IT team is working to resolve the issue. Updates will follow shortly.`,
  },
  {
    id: 'power-outage',
    name: 'Power outage',
    title: 'Power Outage Notification',
    message: `Power outage at [Location]. Backup generators are active. Please save your work and power down non-essential equipment. Updates on restoration will follow.`,
  },
  {
    id: 'suspicious-activity',
    name: 'Suspicious activity',
    title: 'Suspicious Activity Alert',
    message: `Suspicious activity reported at [Location]. Initiate lockdown procedures immediately. Stay in a secure location. Avoid windows and doors. Await further instructions.`,
  },
];

export default {
  APP,
  ACCESS_CONTROL_INTEGRATIONS,
  APPROVAL_STATUS,
  BANNERS,
  DEFAULT_FLOW_NAME,
  DESK_AVAILABILITY,
  DESK_COUNT,
  FLOW_TYPE,
  NON_ASSIGNABLE_FLOWS,
  NON_POINTS_OF_INTEREST,
  GROUP_OPERATION_OPTIONS,
  IFRAME_STATE,
  ITEM,
  LANGUAGE_SUPPORT,
  NEIGHBORHOOD_SCIM_CATEGORIES,
  PERIOD,
  PLAN_LEVEL,
  PLAN_PERIOD: PERIOD,
  PLAN_RANK: RANK,
  RANK,
  RULE_ACTION_OPTIONS,
  RULE_OPERATION_OPTIONS,
  SECONDS_IN_A_DAY,
  SUBSCRIPTION_STATUS,
  getEnum,
};

export const ANNOUNCEMENT_TEMPLATE_EMPLOYEE_AUDIENCES = [
  {
    label: 'Employees checked in',
    value: 'ALL_EMPLOYEES_CHECKED_IN',
  },
  {
    label: 'Employees scheduled but not checked in ',
    value: 'ALL_EMPLOYEES_WITH_RESERVATION',
  },
  {
    label: 'Employees with primary location of send location',
    value: 'ALL_EMPLOYEES_AT_DEFAULT_LOCATION',
  },
  {
    label: 'Employee directory of send location',
    value: 'ALL_EMPLOYEES_AT_LOCATION',
  },
];

export const ANNOUNCEMENT_TEMPLATE_VISITOR_AUDIENCES = [
  {
    label: 'Visitors signed in',
    value: 'ALL_VISITORS_CHECKED_IN',
  },
  {
    label: 'Visitors invited but not signed in',
    value: 'ALL_VISITORS_WITH_RESERVATION',
  },
];

export const ANNOUNCEMENT_TEMPLATE_VISITOR_AUDIENCES_M2 = [
  {
    label: 'Visitors signed in',
    value: 'ALL_VISITORS_CHECKED_IN',
  },
  {
    label: 'Visitors invited but not signed in',
    value: 'ALL_VISITORS_WITH_RESERVATION',
  },
  {
    label: 'Visitors signed out',
    value: 'ALL_VISITORS_SIGNED_OUT',
  },
];

export const ANNOUNCEMENT_RECIPIENT_OPTIONS = [
  {
    label: 'All recipients',
    value: 'all',
  },
  {
    label: 'Employees',
    value: 'employee',
  },
  {
    label: 'Visitors',
    value: 'visitor',
  },
];

export const ANNOUNCEMENT_STATUS_OPTIONS = [
  {
    label: 'All statuses',
    value: 'all',
  },
  {
    icon: 'icon-cross-small-red !h-[12px]',
    label: 'Email not sent',
    value: 'email-not-sent',
  },
  {
    icon: 'icon-cross-small-red !h-[12px]',
    label: 'Push not sent',
    value: 'push-not-sent',
  },
  {
    icon: 'icon-cross-small-red !h-[12px]',
    label: 'SMS not sent',
    value: 'sms-not-sent',
  },
];

export const EMERGENCY_NOTIFICATION_STATUS_OPTIONS = [
  {
    label: 'All statuses',
    value: 'all',
  },
  {
    icon: 'icon-shield-light-gray',
    label: 'Not marked as safe',
    value: 'not-marked-as-safe',
  },
  {
    icon: 'icon-shield-green',
    label: 'Marked as safe',
    value: 'marked-as-safe',
  },
  {
    icon: 'icon-cross-small-red !h-[12px]',
    label: 'Email not sent',
    value: 'email-not-sent',
  },
  {
    icon: 'icon-cross-small-red !h-[12px]',
    label: 'Push not sent',
    value: 'push-not-sent',
  },
  {
    icon: 'icon-cross-small-red !h-[12px]',
    label: 'SMS not sent',
    value: 'sms-not-sent',
  },
];

export type ANNOUNCEMENT_EMPLOYEE_AUDIENCE =
  | 'self'
  | 'all_employees_at_location'
  | 'all_employees_at_default_location'
  | 'all_employees_with_reservation'
  | 'all_employees_checked_in'
  | 'custom_employee_audience'
  | 'employees_without_phone_numbers';

export type ANNOUNCEMENT_VISITOR_AUDIENCE = 'all_visitors_with_reservation' | 'all_visitors_checked_in';

export const ANNOUNCEMENT_CHANNEL_NAME = Object.freeze({
  sms: 'SMS',
  push: 'Push',
  email: 'Email',
});

export const ANNOUNCEMENT_RECIPIENT_GROUP_NAME = Object.freeze({
  self: 'Just you',
  all_employees_at_location: 'Employee directory of %{location_name}',
  all_employees_at_default_location: 'Employees with primary location of %{location_name}',
  all_employees_with_reservation: 'Employees scheduled but not checked in',
  all_employees_checked_in: 'Employees checked in',
  custom_employee_audience: 'Custom employee audience',
  employees_without_phone_numbers: 'Employees without phone numbers',
  all_visitors_with_reservation: 'Visitors invited but not signed in',
  all_visitors_checked_in: 'Visitors signed in',
  all_visitors_signed_out: 'Visitors signed out',
});

export const ANNOUNCEMENT_MESSAGE_VARIABLES = [
  {
    label: 'Time',
    value: 'time',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Location name',
    value: 'location_name',
  },
];

export const ANNOUNCEMENT_RECIPIENT_FILTERS = Object.freeze({
  email_sent: 'email_sent',
  push_sent: 'push_sent',
  sms_sent: 'sms_sent',
  recipient_type: 'recipient_type',
  notification_status_equal_to: 'notification_status_equal_to',
  marked_as_safe: 'marked_as_safe',
});
