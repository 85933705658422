import Controller from '@ember/controller';
import { action, get, set } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, timeout, dropTask } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type AjaxService from 'garaje/services/ajax';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ImpressionsService from 'garaje/services/impressions';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { loadSrcDoc } from 'garaje/utils/script-loader';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import { or } from 'macro-decorators';

import type { VisitorsSettingsInvitesRouteModel } from './route';

export default class VisitorsSettingsInvitesController extends Controller {
  declare model: VisitorsSettingsInvitesRouteModel;

  @service declare ajax: AjaxService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare impressions: ImpressionsService;
  @service declare state: StateService;
  @service declare statsig: StatsigService;

  @tracked editEmailVisible = true;
  @tracked previewEmailObject: { body: unknown } | null = null;
  @tracked testEmailSent = false;
  @tracked securityDeskLinkCallback?: Promise<void>;
  @tracked preRegistrationLinkCallback?: Promise<void>;
  @tracked lastMarkdown?: string;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin!: boolean;

  get hasAnyFlowsWithIdScanningEnabled(): boolean {
    let flows = this.model.allFlowsForCurrentLocation;
    flows = flows && flows.length ? flows.filter((flow) => flow.enabled) : [];
    // eslint-disable-next-line ember/no-get
    flows = flows.filter((vt) => get(vt, 'idScanPage.enabled') === true);
    return flows.length > 0;
  }

  get canShowAddEmployeesTooltip(): boolean {
    if (
      this.model.currentLocation?.preRegistrationEnabled &&
      this.state?._companyMeta?.['employees-count'] === 1 &&
      this.featureFlags.isEnabled('growth_employee_directory_empty_state')
    ) {
      return true;
    }
    return false;
  }

  loadSrcDoc(): Promise<void> {
    return loadSrcDoc().then((didLoad) => {
      if (didLoad) {
        const [iframe] = document.getElementsByClassName('preRegistrationIframe');

        if (iframe) {
          // @ts-ignore
          set(window.srcDoc, iframe, this.previewEmailObject.body);
        }
      }
    });
  }

  get showSetupGuide(): boolean {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  toggleNearVisitScreeningEnabled = dropTask(async (value: boolean) => {
    this.model.currentLocation!.nearVisitScreeningEnabled = value;
    await this.saveLocationTask.perform(this.model.currentLocation!);
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.VR_INVITES_NEAR_VISIT_REGISTRATION[value ? 'ENABLED' : 'DISABLED'],
    );
  });

  toggleCCReceptionistEnabled = dropTask(async (value: boolean) => {
    this.model.currentLocation!.ccReceptionistEnabled = value;
    await this.saveLocationTask.perform(this.model.currentLocation!);
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.VR_INVITES_INVITE_NOTIFICATION[value ? 'ENABLED' : 'DISABLED'],
    );
  });

  togglePreRegistration = dropTask(async (shouldEnablePreRegistration) => {
    const { currentLocation } = this.model;

    if (shouldEnablePreRegistration) {
      currentLocation!.preRegistrationEnabled = true;
    } else {
      currentLocation!.preRegistrationEnabled = false;
      if (currentLocation!.preRegistrationRequiredEnabled) {
        currentLocation!.preRegistrationRequiredEnabled = false;
      }
    }

    await this.saveLocationTask.perform(currentLocation!);
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.VR_INVITES_PRE_REGISTRATION[shouldEnablePreRegistration ? 'ENABLED' : 'DISABLED'],
    );
  });

  togglePreRegistrationRequiredEnabled = dropTask(async (value: boolean) => {
    this.model.currentLocation!.preRegistrationRequiredEnabled = value;
    await this.saveLocationTask.perform(this.model.currentLocation!);
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.VR_INVITES_PRE_REGISTRATION_REQUIRED[value ? 'ENABLED' : 'DISABLED'],
    );
  });

  saveLocationTask = dropTask(async (location: LocationModel) => {
    try {
      await location.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      location.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  sendTestEmailTask = task(async () => {
    const locationId = this.model.currentLocation!.id;
    const url = urlBuilder.v2.sendInvitePreviewUrl(locationId);
    const promise = this.ajax.request(url, {
      type: 'POST',
      data: {
        notes: this.model.currentLocation!.preRegistrationNotes,
      },
    });
    try {
      await promise;
    } catch (e) {
      // Response is not expected to be valid JSON, so ignore syntax error
      if (!(e instanceof SyntaxError)) {
        const errorText = parseErrorForDisplay(e);
        this.flashMessages.showAndHideFlash('error', errorText);
      }
    }
    this.flashMessages.showAndHideFlash('success', 'Sent!');
    this.testEmailSent = true;
    await timeout(zft(2500));
    this.testEmailSent = false;
  });

  enableSecurityDeskLink = task(async () => {
    try {
      const { currentLocation } = this.model;
      currentLocation!.securityDeskLinkEnabled = true;
      await currentLocation!.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      await this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_INVITES_SECURITY_DESK_LINK['ENABLED']);
    } catch (_e) {
      this.flashMessages.showAndHideFlash('warning', 'Error saving!');
    }
  });

  disableSecurityDeskLink = task(async () => {
    try {
      const { currentLocation } = this.model;
      currentLocation!.securityDeskLinkEnabled = false;
      await currentLocation!.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      await this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_INVITES_SECURITY_DESK_LINK['DISABLED']);
    } catch (_e) {
      this.flashMessages.showAndHideFlash('warning', 'Error saving!');
    }
  });

  @action
  preRegistrationNotesUpdated(notes: string): void {
    this.model.currentLocation!.preRegistrationNotes = notes;
  }

  @action
  regeneratePreRegistrationLink(): void {
    if (
      window.confirm('Are you sure want to regenerate the employee invitation link? Existing link will stop working.')
    ) {
      this.preRegistrationLinkCallback = this.model.currentLocation!.generatePreRegistrationLink();
    }
  }

  @action
  regenerateSecurityDeskLink(): void {
    this.securityDeskLinkCallback = this.model.currentLocation!.generateSecurityDeskLink();
  }

  @action
  getEmailPreview(): void {
    const preRegistrationNotes = this.model.currentLocation!.preRegistrationNotes;

    if (this.lastMarkdown !== preRegistrationNotes) {
      this.lastMarkdown = preRegistrationNotes;
      this.previewEmailObject = null;
    }

    const locationId = this.model.currentLocation!.id;

    void this.ajax
      .request(urlBuilder.v2.invitePreviewUrl(locationId), {
        type: 'POST',
        data: { notes: preRegistrationNotes },
      })
      .then((response: (typeof this)['previewEmailObject']) => {
        this.previewEmailObject = response;
      })
      .finally(() => {
        // @ts-ignore
        scheduleOnce('afterRender', this, 'loadSrcDoc');
      });
  }

  @action
  trackTooltipShown(): void {
    this.statsig.logEvent('employee-invite-tooltip-seen');
  }

  @action
  trackTooltipLinkClick(): void {
    this.statsig.logEvent('employee-invite-tooltip-directory-clicked');
  }
}
