import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import Controller, { inject as controller } from '@ember/controller';
import { action, get, set, setProperties } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type { AsyncBelongsTo } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type { DetailedChangeset } from 'ember-changeset/types';
import { all, task } from 'ember-concurrency';
import newGroupInviteChangeset from 'garaje/changesets/new-group-invite';
import newInviteChangeset from 'garaje/changesets/new-invite';
import type ConfigModel from 'garaje/models/config';
import type EntryModel from 'garaje/models/entry';
import type FlowModel from 'garaje/models/flow';
import type GroupInviteModel from 'garaje/models/group-invite';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type ProtectedController from 'garaje/pods/protected/controller';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type LocationFeatureFlagsService from 'garaje/services/location-feature-flags';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { NON_ASSIGNABLE_FLOWS } from 'garaje/utils/enums';
import { createCapacityValidator } from 'garaje/utils/locations-capacity';
import parseDateFromQP from 'garaje/utils/parse-date-from-qp';
import type { ModelData } from 'garaje/utils/type-utils';
import addOrUpdateUserData from 'garaje/utils/user-data';
import { filter, sortBy } from 'macro-decorators';
import moment from 'moment-timezone';

import type { VisitorsInvitesIndexRouteModelParams } from '../index/route';

import type { VisitorsInvitesNewRouteModel, VisitorsInvitesNewRouteParams } from './route';

const PURPOSE_FIELD = 'Purpose of visit';

interface InviteData {
  config?: ConfigModel;
  flows: FlowModel[];
  location: LocationModel;
  priorInvite?: InviteModel;
}

export default class VisitorsInvitesNewController extends Controller {
  declare model: VisitorsInvitesNewRouteModel;

  @service declare featureFlags: FeatureFlagsService;
  @service declare locationFeatureFlags: LocationFeatureFlagsService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;

  @controller('protected') protectedController!: ProtectedController;

  queryParams = ['type', 'expectedArrivalTime', 'name', 'email', 'previousInvite'];

  @tracked data: InviteData | null = null;
  @tracked expectedArrivalTime = '';
  /**
   * set from QP
   */
  @tracked type: 'single' | 'group' | 'inline' = 'single';
  @tracked name = '';
  @tracked email = '';
  @tracked previousInvite = '';

  @filter(
    'data.flows',
    ({ employeeCentric, type }: FlowModel) => !(<string[]>NON_ASSIGNABLE_FLOWS).includes(type) && !employeeCentric,
  )
  flowOptionsInit!: InviteData['flows'];
  @sortBy('flowOptionsInit', 'position') flowOptions!: InviteData['flows'];

  get locationIsConnectedToProperty(): boolean {
    return this.model.connectedTenants?.toArray().length > 0;
  }

  @action
  modelDidSave(date: string, goToIndex = true, invite: InviteModel | null = null): void {
    const { location } = this.data!;

    if (goToIndex) {
      const queryParams: Partial<VisitorsInvitesIndexRouteModelParams> = {};

      if (date) {
        queryParams.date = moment(date).format('YYYY-MM-DD');
      }

      this.transitionToRoute('visitors.invites.index', { queryParams }); // eslint-disable-line ember/no-deprecated-router-transition-methods
      this.maybeSwitchCurrentLocation(location);
    } else {
      // This runs when you "Invite and add another"
      // These attributes are copied to the new invite
      const inviteAttrs = {
        flowName: invite!.flowName,
        expectedArrivalTime: invite!.expectedArrivalTime,
        employee: invite!.employee,
        inviterName: invite!.inviterName,
      };
      const queryParams: Partial<VisitorsInvitesNewRouteParams> = {};

      queryParams.name = '';
      queryParams.email = '';

      void this.buildInvite.perform(location, inviteAttrs);
      this.transitionToRoute('visitors.invites.new', { queryParams }); // eslint-disable-line ember/no-deprecated-router-transition-methods
    }
    if (this.previousInvite && invite) {
      this.trackInvitesRepeatInviteCreated(this.previousInvite, invite.id);
      this.previousInvite = '';
    }
  }

  @action
  groupInviteDidSave(date?: string, groupInvite?: GroupInviteModel): void {
    const { location } = this.data!;
    const queryParams: Partial<VisitorsInvitesIndexRouteModelParams> = {};

    if (groupInvite) {
      this.transitionToRoute('visitors.invites.groups.show', groupInvite.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
    } else {
      if (date) {
        queryParams.date = moment(date).format('YYYY-MM-DD');
      }

      this.transitionToRoute('visitors.invites.index', { queryParams }); // eslint-disable-line ember/no-deprecated-router-transition-methods
    }

    this.maybeSwitchCurrentLocation(location);
  }

  @action
  dateChanged(date: string): void {
    const now = moment();
    let expectedArrivalTime = moment(date);
    let dateString = expectedArrivalTime.format('YYYY-MM-DDTHH:mm');

    if (expectedArrivalTime.isBefore(now)) {
      expectedArrivalTime = moment(parseDateFromQP(now.format('YYYY-MM-DDTHH:mm')));
      dateString = expectedArrivalTime.format('YYYY-MM-DDTHH:mm');
    }

    if (this.type === 'single') {
      set(this.buildInvite.lastSuccessful!.value!.changeset, 'expectedArrivalTime', expectedArrivalTime.toDate());
    }

    if (this.type === 'group') {
      set(
        this.buildGroupInviteTask.lastSuccessful!.value!.changeset,
        'expectedArrivalTime',
        expectedArrivalTime.toDate(),
      );
    }

    // update query-param
    this.expectedArrivalTime = dateString;
  }

  @action
  flowChanged(flowName: string, changeset: DetailedChangeset<InviteModel>): void {
    const { location } = this.data!;

    void this.buildInvite.perform(location, { flowName }, changeset);
  }

  @action
  flowChangedForGroupInvite(flow: FlowModel, changeset: DetailedChangeset<GroupInviteModel>): void {
    const { location } = this.data!;

    void this.buildGroupInviteTask.perform(location, { flow }, changeset);
  }

  @action
  modelDidPartialSave({ id: invite_id }: InviteModel): void {
    void this.router.transitionTo('visitors.invites.show', invite_id);
  }

  maybeSwitchCurrentLocation(location: LocationModel): void {
    if (this.model.location !== location) {
      this.protectedController.send('switchLocation', location.id);
    }
  }

  trackInvitesRepeatInviteCreated(previous_invite_id: string, invite_id: string): void {
    const properties = { invite_id, previous_invite_id };
    this.metrics.trackEvent('Invites Repeat Invite Created', properties);
  }

  copyUserDataFromPreviousInvite(invite: InviteModel, previousInvite: InviteModel): InviteModel {
    const fieldsToCopy = ['Your Phone Number'];
    // eslint-disable-next-line ember/no-get
    const entryData = <EntryModel['userData']>get(previousInvite, 'entry.userData');

    for (const field of fieldsToCopy) {
      const datum = entryData?.findBy('field', field) || previousInvite.userData.findBy('field', field);

      if (datum?.value) addOrUpdateUserData(invite, datum.field, datum.value);
    }

    return invite;
  }

  fetchData = task(async (location: LocationModel) => {
    const { previousInvite } = this;
    const dataToLoad: [AsyncBelongsTo<ConfigModel>, Promise<FlowModel[]>, undefined | Promise<InviteModel>] = [
      location.config,
      this.state.loadFlows({ reload: false, locationId: location.id }),
      undefined,
    ];

    if (previousInvite) {
      dataToLoad[2] = this.store.findRecord('invite', this.previousInvite, { include: ['entry'] });
    }

    const [config, flows, priorInvite] = await all(dataToLoad);

    this.data = { config, flows, location, priorInvite };

    await this.buildInvite.perform(location);

    return this.data;
  });

  buildInvite = task(
    async (
      location: LocationModel,
      inviteAttrs: Partial<ModelData<InviteModel>> = {},
      existingChangeset: DetailedChangeset<InviteModel> | null = null,
    ) => {
      let invite: InviteModel;

      const { flowOptions: flows } = this;
      const { priorInvite } = this.data ?? {};

      const flow = flows?.find((flow) => flow.name == inviteAttrs.flowName) || priorInvite?.flow || (flows && flows[0]);
      // eslint-disable-next-line ember/no-get
      const signInFields = <NativeArray<SignInFieldModel>>await get(flow, 'signInFieldPage.signInFields') || A();
      const hostField = signInFields.findBy('identifier', 'host');
      const hostFieldIsRequired = Boolean(hostField?.required);

      // Fun with async code.
      // Sometimes, query params haven't reset yet before the task begins.
      // Load controller state after the await/yield to reduce unintended
      // duplication of name, email.
      const { expectedArrivalTime, name, email, previousInvite } = this;

      // priorInvite may be an invite record, previousInvite is the ID passed by query param
      // Both must be detected (and match) to enable data copying from an earlier invite
      const isPreviousInvite = previousInvite && priorInvite?.id === previousInvite;
      const date = expectedArrivalTime ? parseDateFromQP(expectedArrivalTime) : '';

      if (existingChangeset) {
        invite = existingChangeset.data;
      } else {
        invite = await location.createInvite(date, name, email);

        const flowField = invite.userData.findBy('field', PURPOSE_FIELD);

        // There is a "no default flow" feature flag to be aware of
        if (flowField || isPreviousInvite) {
          // eslint-disable-next-line ember/no-get
          flowField!.value = <string>get(flow, 'name');
          // eslint-disable-next-line ember/no-get
          inviteAttrs.flowName = <string>get(flow, 'name');
          invite.flow = flow;
        }

        if (isPreviousInvite) {
          this.copyUserDataFromPreviousInvite(invite, priorInvite);
        }

        // @ts-ignore not the safest way to assign fields to the invite, consider refactoring
        setProperties(invite, inviteAttrs);
      }
      const changeset = newInviteChangeset(invite, {
        hostFieldIsRequired,
        validateCapacity: createCapacityValidator(this.store, location),
        checkEmailRequired:
          this.featureFlags.isEnabled('visitors-required-email-field') ||
          this.locationFeatureFlags.isEnabled('visitors-required-email-field-by-location'),
      });

      if (existingChangeset) {
        existingChangeset.changes.forEach((change) => {
          // From Ember Changeset docs:
          // "It is recommended to use changeset.set(...) instead of Ember.set(changeset, ...)"
          // eslint-disable-next-line ember/use-ember-get-and-set
          changeset.set(change['key'], change['value']);
        });
      }

      return { changeset, invite, hostFieldIsRequired };
    },
  );

  buildGroupInviteTask = task(
    async (
      location: LocationModel,
      attrs: Partial<ModelData<GroupInviteModel>> = {},
      existingChangeset: DetailedChangeset<GroupInviteModel> | null = null,
    ) => {
      let groupInvite;

      const { flowOptions: flows } = this;
      const noAutoSelectFlow = this.featureFlags.isEnabled('visitors-no-default-visitor-type') && flows?.length > 1;
      const flow = attrs.flow || (noAutoSelectFlow ? null : flows && flows[0]);
      // eslint-disable-next-line ember/no-get
      const signInFields = <NativeArray<SignInFieldModel>>await get(flow ?? {}, 'signInFieldPage.signInFields') || A();
      const hostField = signInFields.findBy('identifier', 'host');
      const hostFieldIsRequired = Boolean(hostField?.required);
      const { expectedArrivalTime } = this;
      const date = expectedArrivalTime ? parseDateFromQP(expectedArrivalTime) : '';

      if (existingChangeset) {
        groupInvite = existingChangeset.data;
      } else {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        groupInvite = await location.createGroupInvite({ date });
        groupInvite.flow = flow!;
        // @ts-ignore not the safest way to assign fields to the group invite, consider refactoring
        setProperties(groupInvite, attrs);
      }

      const changeset = newGroupInviteChangeset(groupInvite, { hostFieldIsRequired });

      if (existingChangeset) {
        existingChangeset.changes.forEach((change) => {
          // From Ember Changeset docs:
          // "It is recommended to use changeset.set(...) instead of Ember.set(changeset, ...)"
          // eslint-disable-next-line ember/use-ember-get-and-set
          changeset.set(change['key'], change['value']);
        });
      }

      return { changeset, groupInvite, hostFieldIsRequired };
    },
  );

  setupGroupInviteTask = task(async (location: LocationModel) => {
    const dataToLoad = [location.config, this.state.loadFlows({ reload: false, locationId: location.id })] as const;
    const [config, flows] = await all(dataToLoad);
    const currentChangeset = this.buildGroupInviteTask.lastSuccessful?.value?.changeset;
    let attrs = {};

    if (currentChangeset?.isNew) {
      attrs = {
        groupName: currentChangeset.groupName,
        csv: currentChangeset.csv,
        notifyVisitor: currentChangeset.notifyVisitor,
        includeGroupNameInEmail: currentChangeset.includeGroupNameInEmail,
        privateNotes: currentChangeset.privateNotes,
      };
    }

    this.data = { config, flows, location };

    await this.buildGroupInviteTask.perform(location, attrs);

    return this.data;
  });
}
