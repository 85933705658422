import { service } from '@ember/service';
import { Ability } from 'ember-can';
import { isProductDisabled } from 'garaje/helpers/product-disabled';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';

export default class OccupancyGlobalSettingAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare authz: AuthzService;

  get canVisit(): boolean {
    if (isProductDisabled('occupancy')) {
      return false;
    }

    const hasCorrectPlan = Boolean(this.state.workplaceSubscription?.canAccessOccupancyAnalytics);
    const hasCorrectPermission = this.authz.hasPermissionAtCurrentCompany(
      Permission.ANALYTICS_GLOBAL_OCCUPANCY_SETTINGS_UPDATE,
    );

    return hasCorrectPlan && hasCorrectPermission;
  }
}
