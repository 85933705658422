import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { alias, equal, filterBy, or } from 'macro-decorators';
import { action, setProperties } from '@ember/object';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { dropTask, lastValue, restartableTask, timeout } from 'ember-concurrency';
import normalizeCouponCode from 'garaje/utils/normalize-coupon-code';
import zft from 'garaje/utils/zero-for-tests';
import { WORKPLACE_SEAT_MINIMUM } from 'garaje/utils/plan-details';

/**
 * @param {Array<string>}               activeUnits
 * @param {boolean}                     deliveriesLimitExceeded
 * @param {string}                      normalizedPlanName
 * @param {Function}                    onSuccess
 * @param {Class<PaymentSourceModel>}   paymentSource
 * @param {Class<Plan>}                 plan
 * @param {string}                      planName
 * @param {Class<Subscription>}         subscription
 */
export default class BillingUpgradeComponent extends Component {
  @service featureFlags;
  @service flashMessages;
  @service impressions;
  @service metrics;
  @service('skinnyLocations') skinnylocationsService;
  @service state;
  @service store;

  @tracked couponCode = null;
  @tracked lastValidCouponCode = null;
  @tracked period = null;
  @tracked isUpgradeDisabledMinSeat = false;
  // initialize quantity with how many seats the user needs to meet the min seat requirement of workplace premium plus
  @tracked quantity =
    this.isWorkplace && this.args.subscription.quantity < WORKPLACE_SEAT_MINIMUM
      ? WORKPLACE_SEAT_MINIMUM - this.args.subscription.quantity
      : 0;
  @tracked companyMeta;
  @tracked activeLocations = [];
  @tracked locationsToDeactivateCount = 0;

  @lastValue('fetchEstimateTask') estimate;
  @alias('estimate.nextInvoiceEstimate.lineItems.firstObject.unitAmount') unitCost;
  @equal('args.subscription.app', 'deliveries') isDeliveries;
  @equal('args.subscription.app', 'visitors') isVisitors;

  @equal('args.subscription.app', 'empxp') isWorkplace;
  @filterBy('estimate.nextInvoiceEstimate.lineItems', 'entityType', 'plan') planLineItems;
  @or('updateQuantityTask.isRunning', 'fetchEstimateTask.isRunning') isLoading;

  minSeatsWorkplaceUpgrade = WORKPLACE_SEAT_MINIMUM;
  showBillingPeriodSelector = !this.isWorkplace;

  @action
  onInsert() {
    const period = ['monthly', 'yearly'].includes(this.args.subscription.period)
      ? this.args.subscription.period
      : 'monthly';
    this.period = this.isWorkplace ? 'yearly' : period;
    this.fetchEstimateTask.perform();
  }

  get discounts() {
    return this.estimate?.nextInvoiceEstimate.discounts || [];
  }

  get existingQuantity() {
    return this.isDeliveries && this.args.deliveriesLimitExceeded ? 1 : this.args.subscription.quantity;
  }

  get newCost() {
    if (!this.unitCost) {
      return 0;
    }
    return this.unitCost * this.quantity;
  }

  get newSubscriptionCost() {
    if (!this.unitCost) {
      return 0;
    }
    return this.unitCost * this.existingQuantity;
  }

  get periodPriceInCents() {
    return (this.planLineItems.firstObject?.unitAmount || 0) * this.existingQuantity;
  }

  get yearlySavingsInDollars() {
    return (this.args.plan.monthlyPrice - this.args.plan.yearlyPrice) * 12 * this.existingQuantity;
  }

  @dropTask
  *applyCouponCodeTask(couponCode) {
    this.couponCode = normalizeCouponCode(couponCode);
    yield this.fetchEstimateTask.perform();
    this.couponCode = null;
  }

  @restartableTask
  *fetchEstimateTask() {
    const couponCode = this.couponCode !== null ? this.couponCode : this.lastValidCouponCode;
    try {
      const estimate = yield this.store
        .createRecord('subscription-estimate', {
          app: this.args.subscription.app,
          quantity: this.existingQuantity + this.quantity,
          plan: this.args.normalizedPlanName,
          period: this.period,
          couponCode,
          subscription: this.args.subscription,
        })
        .save();

      this.lastValidCouponCode = this.couponCode;
      this.couponCode = null;

      return estimate;
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  }

  getLocationsToDeactivate(newLocationCount) {
    this.activeLocations = this.skinnylocationsService.active;

    if (this.activeLocations) {
      this.locationsToDeactivateCount = this.activeLocations.length - newLocationCount;
      return this.locationsToDeactivateCount;
    }
    return 0;
  }

  @dropTask
  *handleSubmitTask(event) {
    event.preventDefault();
    this.locationsToDeactivateCount = 0;

    if (this.fetchEstimateTask.isRunning) {
      return;
    }

    try {
      const oldQuantity = this.args.subscription.quantity;
      const newQuantity = this.existingQuantity + this.quantity;
      const oldPlan = this.args.subscription.normalizedPlanName;
      const oldPeriod = this.args.subscription.period;

      if (this.featureFlags.isEnabled('growth-show-location-gating')) {
        this.locationsToDeactivateCount = this.getLocationsToDeactivate(newQuantity);

        if (this.locationsToDeactivateCount > 0) return;
        else
          yield this.impressions.postImpression.perform(
            IMPRESSION_NAMES.LOCATION_GATING_MODAL_UPGRADE_CLICKED[this.args.planName.toUpperCase()],
          );
      }

      if (this.args.subscription.isDesks && this.args.subscription.onExpiredTrial) {
        yield this.args.subscription.purchase({
          coupon: this.lastValidCouponCode,
          period: this.period,
          quantity: newQuantity,
        });

        yield this.args.subscription.reload();
      } else {
        setProperties(this.args.subscription, {
          coupon: this.lastValidCouponCode,
          period: this.period,
          plan: this.args.normalizedPlanName,
          quantity: newQuantity,
        });

        yield this.args.subscription.save();
      }

      this.flashMessages.showAndHideFlash('success', 'Upgrade successful');
      this.args.onSuccess();
      this.metrics.startJob();
      this.metrics.trackJobEvent('Account modified', {
        product: this.args.subscription.app,
        account_change_type: 'subscription',
        account_change_object: 'plan',
        account_change_action: 'upgrade',
        object_change_from: oldPlan,
        object_change_to: this.args.planName,
      });
      if (oldPeriod !== this.period) {
        this.metrics.trackJobEvent('Account modified', {
          product: this.args.subscription.app,
          account_change_type: 'subscription',
          account_change_object: 'period',
          account_change_action: 'updated',
          object_change_from: oldPeriod,
          object_change_to: this.period,
        });
      }
      if (oldQuantity !== newQuantity) {
        this.metrics.trackJobEvent('Account modified', {
          product: this.args.subscription.app,
          account_change_type: 'subscription',
          account_change_object: 'quantity',
          account_change_action: 'expansion',
          object_change_from: oldQuantity,
          object_change_to: newQuantity,
        });
      }
    } catch (e) {
      this.args.subscription.rollbackAttributes();
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }

  @restartableTask
  *updateQuantityTask({ target }) {
    let { value: newQuantity } = target;
    if (!newQuantity) {
      return;
    }
    newQuantity = parseInt(newQuantity, 10);
    if (isNaN(newQuantity) || newQuantity < 0 || newQuantity > 99) {
      // The user has typed an invalid value into the input
      target.value = this.quantity;
    } else {
      this.quantity = newQuantity;
      if (this.isWorkplace) {
        if (this.args.subscription.quantity + newQuantity < WORKPLACE_SEAT_MINIMUM) {
          this.isUpgradeDisabledMinSeat = true;
          return;
        } else {
          this.isUpgradeDisabledMinSeat = false;
        }
      }
      yield timeout(zft(500));
      yield this.fetchEstimateTask.perform(this.quantity);
    }
  }
}
