import { action } from '@ember/object';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { type Task } from 'ember-concurrency';
import type DeviceModel from 'garaje/models/device';
import type PrinterModel from 'garaje/models/printer';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type IpadIconService from 'garaje/services/ipad-icon';
import type StateService from 'garaje/services/state';
import deviceStatus from 'garaje/utils/decorators/device-status';
import { type App } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { localCopy } from 'tracked-toolbox';
interface IpadDetailsArgs {
  context?: App;
  currentAppVersion: string;
  deleteIpadTask: Task<void, []>; // this component receives a curried task with the device already passed
  isPrinterEnabled: boolean;
  model: DeviceModel;
  printers: PrinterModel[];
}

@deviceStatus
class IpadDetails extends Component<IpadDetailsArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare ipadIcon: IpadIconService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare currentAdmin: CurrentAdminService;
  @tracked vfdEnabled: boolean = false;

  @localCopy('args.model') declare model: DeviceModel;
  @localCopy('args.isPrinterEnabled') declare isPrinterEnabled: boolean;
  @localCopy('args.currentAppVersion') declare currentAppVersion: string;

  declare isStatusBad: boolean; // from @deviceStatus decorator

  progressImgPath = '/assets/images/orange-progress-small.svg';

  constructor(...args: unknown[]) {
    // @ts-ignore
    super(...args);

    void this.loadVfdEnabled();
  }

  get ipadIconURI(): string {
    const deviceType = this.model.deviceType;
    const isSleeping = this.model.isSleeping;
    const isStatusBad = this.isStatusBad;

    return this.ipadIcon.getIconURI(deviceType, isSleeping, isStatusBad);
  }

  assignPrinterTask = dropTask(async (printer: PrinterModel) => {
    const model = this.model;
    model.printer = printer;
    try {
      await model.save();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  @action
  printerSelected(printer: PrinterModel): void {
    void this.assignPrinterTask.perform(printer);
  }

  get hasVisitorsAccess(): boolean {
    const { vrSubscription } = this.state;
    return !isBlank(vrSubscription) && !vrSubscription?.cancelled;
  }

  get isVisitorsEnabledForLocation(): boolean {
    const { currentLocation } = this.state;
    return !currentLocation?.disabled;
  }

  @action
  async loadVfdEnabled(): Promise<void> {
    const vfdConfiguration = await this.state.currentLocation?.getVfdConfiguration();
    this.vfdEnabled = vfdConfiguration?.enabled ?? false;
  }

  get hasVfdAccess(): boolean {
    return (
      (this.state.vfdSubscription?.canAccessVirtualFrontDesk ||
        this.state.visitorsSubscription?.canAccessVirtualFrontDesk) ??
      false
    );
  }
}

export default IpadDetails;
