import { Button, FormLayout, Select, SelectOption } from '@envoy/polarwind-react';
import { Analytics, FormCheckbox, FormMultiselect, FormSelect, FormTextField } from '@envoy/react-rich-components-core';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { MESSAGE_AUDIENCE_TYPES } from '../../data/message-audience-types';
import { MESSAGE_EMPLOYEE_AUDIENCES } from '../../data/message-employee-audiences';
import { MESSAGE_VISITOR_AUDIENCES } from '../../data/message-visitor-audiences';
import { Audiences, GQLAudiences } from '../../types/audience';
import { GQLDeliveryMethod, GQLDeliveryMethods } from '../../types/delivery-method';
import { Location } from '../../types/location';
import { GQLTemplate } from '../../types/template';
import { TemplateCategory } from '../../types/template-category';

import './send-message.css';

export type SentMessage = {
  id: string;
  sentCount: number;
};

export type MessageInput = {
  locationId: string;
  title: string;
  message: string;
  deliveryMethods: GQLDeliveryMethod[];
  employeeGroup?: Audiences[];
  visitorGroup?: Audiences[];
  critical: boolean;
  markAsSafe: boolean;
};

export type SendMessageDataAccess = {
  getTemplateCategories(locationId: string): Promise<TemplateCategory[]>;
  getTemplate(locationId: string, templateId: string): Promise<GQLTemplate>;
  getAudiences(locationId: string): Promise<GQLAudiences>;
  getLocations(): Promise<Location[]>;
  sendMessage(input: MessageInput): Promise<SentMessage>;
};

export interface SendMessageProps {
  dataAccess: SendMessageDataAccess;
  analytics: Analytics;
  locationId?: string;
  locationName?: string;
  onSent(message: SentMessage): void;
  onError(errorMessage: string, error: unknown): void;
  showEmployeeGroup: boolean;
  showVisitorGroup: boolean;
  showMultiSelect?: boolean;
  createTemplateUrl: string;
}

const maxMessageLength = 320;

interface MessageForm {
  title: string;
  message: string;
  deliveryMethods: GQLDeliveryMethod[];
  employeeGroup: Audiences[];
  visitorGroup: Audiences[];
  critical: boolean;
  markAsSafe: boolean;
}

export const SendMessage = ({
  dataAccess,
  analytics,
  locationId,
  locationName,
  onSent,
  onError,
  showEmployeeGroup,
  showVisitorGroup,
  showMultiSelect = false,
  createTemplateUrl,
}: SendMessageProps) => {
  const formMethods = useForm<MessageForm>({
    defaultValues: {
      title: '',
      message: '',
      deliveryMethods: [GQLDeliveryMethods.Sms, GQLDeliveryMethods.Email, GQLDeliveryMethods.MobilePush],
      employeeGroup: [],
      visitorGroup: [],
      critical: true,
      markAsSafe: true,
    },
    mode: 'onChange',
  });
  const [templateCategories, setTemplateCategories] = useState<TemplateCategory[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [selectedLocationId, setSelectedLocationId] = useState<string | undefined>(locationId);
  const [selectedLocationName, setSelectedLocationName] = useState<string | undefined>(locationName);
  const [locations, setLocations] = useState<Location[]>([]);
  const [audiences, setAudiences] = useState<GQLAudiences>({
    employees: MESSAGE_EMPLOYEE_AUDIENCES.map((a) => ({ id: a.value })),
    visitors: MESSAGE_VISITOR_AUDIENCES.map((a) => ({ id: a.value })),
  });
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (locationId) {
      return;
    }

    const asyncFn = async () => {
      const returnedLocations = await dataAccess.getLocations();

      setLocations(returnedLocations);
    };

    asyncFn().catch((error) => {
      onError('Could not load locations', error);
    });
  }, [locationId]);

  useEffect(() => {
    if (!selectedLocationId) {
      return;
    }

    const currentLocation = locations.find((data) => data.id === selectedLocationId);
    setSelectedLocationName(currentLocation?.name);

    const asyncFn = async () => {
      const [returnedTemplateCategories, returnedAudiences] = await Promise.all([
        dataAccess.getTemplateCategories(selectedLocationId),
        dataAccess.getAudiences(selectedLocationId),
      ]);

      setTemplateCategories(returnedTemplateCategories);
      setAudiences(returnedAudiences);
    };

    asyncFn().catch((error) => {
      onError('Could not load data.', error);
    });
  }, [selectedLocationId]);

  const onTemplateSelected = (templateId: string) => {
    setSelectedTemplateId(templateId);
  };

  useEffect(() => {
    if (!selectedTemplateId || !selectedLocationId) {
      return;
    }

    void dataAccess
      .getTemplate(selectedLocationId, selectedTemplateId)
      .then((template) => {
        formMethods.reset({
          ...template,
          visitorGroup: [template.visitorGroup],
          employeeGroup: [template.employeeGroup],
          critical: template.markAsSafe,
        });
      })
      .catch((error) => onError('Could not load template.', error));
  }, [selectedTemplateId, selectedLocationId]);

  const onSubmit = async (data: MessageForm) => {
    try {
      setIsSending(true);

      const sentMessage = await dataAccess.sendMessage({
        ...data,
        employeeGroup: showEmployeeGroup && data.employeeGroup.length > 0 ? data.employeeGroup : undefined,
        visitorGroup: showVisitorGroup && data.visitorGroup.length > 0 ? data.visitorGroup : undefined,
        locationId: selectedLocationId!,
        markAsSafe: data.critical && data.markAsSafe,
      });

      analytics.trackAnalytics('COMMUNICATIONS_SEND_MESSAGE_CLICKED', {
        messageId: sentMessage.id,
        locationId: selectedLocationId,
        announcementTemplateId: selectedTemplateId,
        criticalChecked: data.critical,
        markAsSafeChecked: data.markAsSafe,
        employeeGroup: data.employeeGroup,
        visitorGroup: data.visitorGroup,
        deliveryMethods: data.deliveryMethods,
        titleUsed: !!data.title,
      });

      onSent(sentMessage);
    } catch (error) {
      setIsSending(false);

      onError('Could not send message, please try again.', error);
    }
  };

  const templateOptions = useMemo(
    () =>
      templateCategories.map<SelectOption>((category) => ({
        title: category.name,
        options: category.templates.map((template) => ({ label: template.name, value: template.id })),
      })),
    [templateCategories],
  );

  const replaceLocation = (label: string): string => {
    const locationLabel = selectedLocationName || locationName || 'primary location';
    return label.replace('%{location_name}', locationLabel);
  };

  const employeeGroupOptions = useMemo(
    () =>
      (audiences?.employees || []).map<SelectOption>((employeeGroup) => {
        const modifiedLabel = replaceLocation(MESSAGE_AUDIENCE_TYPES[employeeGroup.id]);
        return {
          label: `${modifiedLabel}${employeeGroup.count !== undefined ? ` (${employeeGroup.count})` : ''}`,
          value: employeeGroup.id,
        };
      }),
    [audiences],
  );

  const visitorGroupOptions = useMemo(
    () =>
      (audiences?.visitors || []).map<SelectOption>((visitorGroup) => ({
        label: `${MESSAGE_AUDIENCE_TYPES[visitorGroup.id]}${visitorGroup.count !== undefined ? ` (${visitorGroup.count})` : ''}`,
        value: visitorGroup.id,
      })),
    [audiences],
  );

  const locationOptions = useMemo(
    () => locations.map((location) => ({ label: location.name, value: location.id })),
    [locations],
  );

  const formDisabled = useMemo(() => !locationId && !selectedLocationId, [locationId, selectedLocationId]);

  const [messageValue, criticalChecked] = formMethods.watch(['message', 'critical']);

  /* eslint-disable @typescript-eslint/no-misused-promises */
  return (
    <FormProvider {...formMethods}>
      <form className="react-form" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <FormLayout>
          {!locationId && (
            <Select
              label="Location to send"
              required={true}
              options={locationOptions}
              onChange={(locId: string) => setSelectedLocationId(locId)}
              preselectValue={false}
              placeholder="Select a location"
              value={selectedLocationId}
              testId="message-name"
            />
          )}
          <Select
            label="Template (optional)"
            options={templateOptions}
            disabled={formDisabled}
            onChange={onTemplateSelected}
            preselectValue={false}
            dropdownClassName="send-message__template-dropdown"
            placeholder="Select a template"
            testId="template-select"
            emptyNode={
              <>
                No templates to choose from.{' '}
                <a className="send-message__link" href={createTemplateUrl}>
                  Create template
                </a>
              </>
            }
          />

          <FormTextField<MessageInput>
            name="title"
            label="Message title (optional)"
            disabled={formDisabled}
            placeholder="e.g. Urgent: Immediate Evacuation Required"
            tooltip="Message titles are not shown in SMS text messages"
            testId="message-title"
          />
          <div className="send-message__message">
            <FormTextField<MessageInput>
              name="message"
              label="Message"
              required="Write a message to send"
              disabled={formDisabled}
              maxLength={{ value: 320, message: 'Reduce the length of your message' }}
              multiline={6}
              placeholder="Write your message"
              testId="message-body"
            />
            <div className="send-message__message-text">
              <div className="send-message__message-error">{formMethods.formState.errors.message?.message}</div>
              <div className="send-message__message-length">
                {messageValue.length}/{maxMessageLength}
              </div>
            </div>
          </div>
          <FormMultiselect<MessageInput>
            name="deliveryMethods"
            label="Delivery method(s)"
            required="You must select at least 1 delivery method"
            disabled={formDisabled}
            options={[
              {
                label: 'SMS',
                value: GQLDeliveryMethods.Sms,
              },
              {
                label: 'Email',
                value: GQLDeliveryMethods.Email,
              },
              {
                label: 'Push Notification',
                value: GQLDeliveryMethods.MobilePush,
              },
            ]}
            placeholder="Select how to deliver this message"
            testId="delivery-methods-select"
          />
          {showEmployeeGroup &&
            (showMultiSelect ? (
              <FormMultiselect<MessageInput>
                name="employeeGroup"
                label="Employee group"
                required="You must select an employee group to receive this message"
                disabled={formDisabled}
                options={employeeGroupOptions}
                placeholder="Select who should receive this message"
                testId="employee-group-multi-select"
              />
            ) : (
              <FormSelect<MessageInput>
                name="employeeGroup"
                label="Employee group"
                required="You must select an employee group to receive this message"
                disabled={formDisabled}
                options={employeeGroupOptions}
                preselectValue={false}
                placeholder="Select who should receive this message"
                testId="employee-group-select"
              />
            ))}
          {showVisitorGroup &&
            (showMultiSelect ? (
              <FormMultiselect<MessageInput>
                name="visitorGroup"
                label="Visitor group"
                required={!showEmployeeGroup ? 'You must select a visitor group to receive this message' : undefined}
                disabled={formDisabled}
                options={visitorGroupOptions}
                placeholder="Select who should receive this message"
                testId="visitor-group-multi-select"
              />
            ) : (
              <FormSelect<MessageInput>
                name="visitorGroup"
                label="Visitor group"
                required={!showEmployeeGroup ? 'You must select a visitor group to receive this message' : undefined}
                disabled={formDisabled}
                options={visitorGroupOptions}
                preselectValue={false}
                placeholder="Select who should receive this message"
                testId="visitor-group-select"
              />
            ))}
          <FormCheckbox<MessageInput>
            name="critical"
            label="Critical or priority message"
            disabled={formDisabled}
            tooltip="Use for time-sensitive updates to ensure your message is sent promptly during high activity times"
            testId="critical-checkbox"
          />
          {criticalChecked && (
            <div className="send-message__mark-as-safe">
              <FormCheckbox<MessageInput>
                name="markAsSafe"
                label="Ask recipients to mark themselves as safe"
                disabled={formDisabled}
                testId="mark-as-safe-checkbox"
              />
            </div>
          )}
          <Button disabled={formDisabled || isSending} data-test-send>
            {isSending ? 'Sending' : 'Send now'}
          </Button>
        </FormLayout>
      </form>
    </FormProvider>
  );
};
