import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default class LocationOverviewSignInFlowsShowLegalDocumentsIndexRoute extends Route {
  model() {
    const { agreements, agreementPage, flow, tracking } = this.modelFor(
      'location-overview.sign-in-flows.show.legal-documents',
    );

    return hash({
      agreements: agreements.filterBy('isDeleted', false).sortBy('position'),
      agreementPage,
      flow,
      tracking,
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.setupTrackingConfigurations.perform();
  }
}
