import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';

export default class VirtualFrontDeskRoute extends Route {
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare abilities: AbilitiesService;

  title = 'Virtual Front Desk';

  beforeModel(): void {
    if (!this.currentAdmin.isGlobalAdmin && !this.currentAdmin.isLocationAdmin) {
      void this.router.transitionTo('unauthorized');
      return;
    }

    if (
      !(
        this.state.vfdSubscription?.canAccessVirtualFrontDesk ||
        this.state.visitorsSubscription?.canAccessVirtualFrontDesk
      )
    ) {
      void this.router.transitionTo('virtual-front-desk.landing-page');
    } else {
      void this.router.transitionTo('virtual-front-desk.devices');
    }
  }
}
