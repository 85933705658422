import config from 'garaje/config/environment';

/**
 *
 * @param {string} path       API path
 * @param {string} baseUrl    base URL for the API
 * @returns {string} url
 */

interface FetchEmployeeInviteOnDateFilter {
  locationId: string | undefined;
  dateTo: string;
  dateFrom: string;
  email: string;
}

export function buildUrl(path: string, baseUrl = config.apiHost): string {
  if (path.charAt(0) !== '/') {
    baseUrl = `${baseUrl}/`;
  }

  return `${baseUrl}${path}`;
}

export default {
  spacesAIApiUrl(): string {
    return buildUrl(`/predict`, config.spacesAIHost);
  },
  bulkDesksUpdate(): string {
    return buildUrl(`/a/rms/desks/bulk-desks-update`, config.rmsHost);
  },
  photoUrl(photoPath: string): string {
    return buildUrl(photoPath);
  },
  analyticsUrl(path: string): string {
    return buildUrl(`/a/visitors/api/v2/analytics/${path}`, config.apiHost);
  },
  envoyLogoutUrl(): string {
    return buildUrl(`/users/sign_out`, config.apiHost);
  },
  pluginOAuth2Url(key: string, app: string, locationId: string): string {
    return buildUrl(`/platform/${key}/oauth2/${app}/connect?state=popup&location_id=${locationId}`, config.apiHost);
  },
  pluginCallbackAuthUrl(key: string, route: string, locationId: string): string {
    return buildUrl(`/platform/${key}/${route}?location_id=${locationId}`, config.apiHost);
  },
  salesforceAuthUrl(): string {
    return buildUrl('/users/auth/salesforce?display=popup', config.envoyHost);
  },
  samlConsumeUrl(): string {
    return buildUrl('/a/saml/consume', config.apiHost);
  },
  samlMetadataUrl(): string {
    return buildUrl('/a/saml/metadata', config.apiHost);
  },
  samlInitUrl(companyId: string): string {
    return buildUrl(`/a/saml/auth/${companyId}`, config.apiHost);
  },
  googleAuthUrl(): string {
    return buildUrl('/users/auth/google_oauth2?state=popup', config.envoyHost);
  },
  appStoreUrl(): string {
    return buildUrl('/apps', config.host);
  },
  billingDashboardUrl(): string {
    return buildUrl('/billing', config.host);
  },
  signupReferralUrl(referralCode: string): string {
    return buildUrl(`/sign_up?ref=${encodeURIComponent(referralCode)}`, config.envoyHost);
  },
  embeddedRoomsLocationAnalyticsUrl(companyId: string): string {
    return buildUrl(`/dashboards/rooms_location_analytics?company_id=${companyId}`, config.customerDashHost);
  },
  embeddedDeliveriesAnalyticsUrl(companyId: string, locationId: string, deliveryAreaId: string): string {
    let url = `/dashboards/deliveries_analytics?company_id=${companyId}&location_id=${locationId}`;
    if (deliveryAreaId) {
      url += `&delivery_area_id=${deliveryAreaId}`;
    }
    return buildUrl(url, config.customerDashHost);
  },
  embeddedProtectLocationAnalyticsUrl(companyId: string, locationId: string): string {
    return buildUrl(
      `/dashboards/protect_location_analytics?company_id=${companyId}&location_id=${locationId}`,
      config.customerDashHost,
    );
  },
  embeddedVisitorsLocationAnalyticsUrl(companyId: string, locationId: string): string {
    return buildUrl(
      `/dashboards/visitors_location_analytics?company_id=${companyId}&location_id=${locationId}`,
      config.customerDashHost,
    );
  },
  embeddedReportListGlobalAnalyticsUrl(companyId: string, locationId: string): string {
    return buildUrl(`/report/list?company_id=${companyId}&location_id=${locationId}`, config.customerDashHost);
  },
  embeddedScheduleReportsModalUrl(
    companyId: string,
    locationId: string,
    flowId: string,
    start: string,
    end: string,
  ): string {
    return buildUrl(
      `/modals/report/invites?company_id=${companyId}&location_id=${locationId}&start=${start}&end=${end}&flow_id=${flowId}`,
      config.customerDashHost,
    );
  },
  embeddedEditScheduleReportsModalUrl(companyId: string, locationId: string, modalId: string): string {
    return buildUrl(
      `/modals/report/edit/${modalId}?company_id=${companyId}&location_id=${locationId}`,
      config.customerDashHost,
    );
  },
  embeddedDeleteScheduleReportsModalUrl(reportId: string, companyId: string): string {
    return buildUrl(`modals/report/delete/${reportId}?company_id=${companyId}`, config.customerDashHost);
  },
  embeddedEntryExportModalUrl(
    companyId: string,
    locationId: string,
    flowId: string,
    start: string,
    end: string,
    label: string,
  ): string {
    return buildUrl(
      `/modals/export/entries?company_id=${companyId}&location_id=${locationId}&start=${start}&end=${end}&flow_id=${flowId}&label=${label}`,
      config.customerDashHost,
    );
  },
  embeddedInviteExportModalUrl(
    companyId: string,
    locationId: string,
    flowId: string | null | undefined,
    start: string,
    end: string,
    label: string,
  ): string {
    return buildUrl(
      `/modals/export/invites?company_id=${companyId}&location_id=${locationId}&start=${start}&end=${end}&flow_id=${flowId}&label=${label}`,
      config.customerDashHost,
    );
  },
  embeddedVisitorsGlobalAnalyticsUrl(companyId: string): string {
    return buildUrl(`/dashboards/visitors_global_analytics?company_id=${companyId}`, config.customerDashHost);
  },
  embeddedDesksLocationAnalyticsUrl(companyId: string, locationId: string): string {
    return buildUrl(
      `/dashboards/desks_location_analytics?company_id=${companyId}&location_id=${locationId}`,
      config.customerDashHost,
    );
  },
  embeddedProtectGlobalAnalyticsUrl(companyId: string): string {
    return buildUrl(`/dashboards/protect_global_analytics?company_id=${companyId}`, config.customerDashHost);
  },
  embeddedMigratedProtectGlobalAnalyticsUrl(companyId: string, loginRequired: boolean): string {
    const redirectUrl = `/protect/global/analytics?company_id=${companyId}`;
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedProtectGlobalReportsUrl(companyId: string, loginRequired: boolean): string {
    const redirectUrl = `/protect/global/reports?company_id=${companyId}`;
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedProtectGlobalReportsModalUrl(companyId: string, loginRequired: boolean): string {
    const redirectUrl = encodeURIComponent(`/protect/global/modal?company_id=${companyId}`);
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedDesksGlobalAnalyticsUrl(companyId: string): string {
    return buildUrl(`/dashboards/desks_global_analytics?company_id=${companyId}`, config.customerDashHost);
  },
  embeddedConnectAnalyticsUrl(companyId: string, loginRequired: boolean): string {
    const redirectUrl = `/connect/analytics?company_id=${companyId}`;
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedConnectOccupancyAnalyticsDemoUrl(companyId: string, loginRequired: boolean): string {
    const redirectUrl = `/connect/occupancy?company_id=${companyId}`;
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedConnectOccupancyAnalyticsUrl(companyId: string, loginRequired: boolean): string {
    const redirectUrl = `/connect/occupancy-analytics?company_id=${companyId}`;
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedOccupancyLocationAnalyticsUrl(companyId: string, locationId: string, loginRequired: boolean): string {
    const redirectUrl = encodeURIComponent(
      `/occupancy/location/analytics?company_id=${companyId}&location_id=${locationId}`,
    );
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedOccupancyLocationReportsModalUrl(companyId: string, locationId: string, loginRequired: boolean): string {
    const redirectUrl = encodeURIComponent(
      `/occupancy/location/modal?company_id=${companyId}&location_id=${locationId}`,
    );
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedWorkplaceActiveUsersModalUrl(companyId: string, loginRequired: boolean): string {
    const redirectUrl = encodeURIComponent(`/workplace/billing?company_id=${companyId}`);
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedOccupancyGlobalAnalyticsUrl(companyId: string, loginRequired: boolean): string {
    const redirectUrl = encodeURIComponent(`/occupancy/global/analytics?company_id=${companyId}`);
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedSettingsGlobalAnalyticsUrl(companyId: string, loginRequired: boolean): string {
    const redirectUrl = encodeURIComponent(`/settings/global/analytics?company_id=${companyId}`);
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedOccupancyCsvUploadDeleteModal(companyId: string, loginRequired: boolean): string {
    const redirectUrl = encodeURIComponent(`/occupancy/csv-upload/delete-modal?company_id=${companyId}`);
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedOccupancyCsvUploadResultsModal(companyId: string, loginRequired: boolean): string {
    const redirectUrl = encodeURIComponent(`/occupancy/csv-upload/results-modal?company_id=${companyId}`);
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedRoombaLocationAnalyticsMigratedUrl(
    companyId = '',
    locationId = '',
    oauthProvider = '',
    loginRequired = true,
  ): string {
    const redirectUrl = encodeURIComponent(
      `/rooms/location/analytics?company_id=${companyId}&location_id=${locationId}&oauth_provider=${oauthProvider}`,
    );
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedOccupancyCopyLinkModal(url: string, loginRequired: boolean, companyId: string, locationId: string): string {
    const redirectUrl = encodeURIComponent(
      `/occupancy/location/copy-link-modal-page?company_id=${companyId}&${
        locationId ? `location_id=${locationId}&` : ''
      }url=${url}`,
    );
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedOccupancyLocationReportListUrl(loginRequired: boolean, companyId: string, locationId: string): string {
    const redirectUrl = encodeURIComponent(
      `/occupancy/location/reports?company_id=${companyId}&location_id=${locationId}`,
    );
    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedAttendanceAnalyticsUrl(loginRequired: boolean, companyId: string, locationId: string, view: string): string {
    const redirectUrl = encodeURIComponent(
      `/attendance/location/analytics?company_id=${companyId}&location_id=${locationId}&view=${view}`,
    );

    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedAttendanceGlobalAnalyticsUrl(loginRequired: boolean, companyId: string, view: string): string {
    const redirectUrl = encodeURIComponent(`/attendance/global/analytics?company_id=${companyId}&view=${view}`);

    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedAttendanceAnalyticsV2Url(
    loginRequired: boolean,
    companyId: string,
    locationId: string,
    view: string,
  ): string {
    const redirectUrl = encodeURIComponent(
      `/attendance/location/analytics-v2?company_id=${companyId}&location_id=${locationId}&view${view}`,
    );

    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  embeddedAttendanceGlobalAnalyticsV2Url(loginRequired: boolean, companyId: string, view: string): string {
    const redirectUrl = encodeURIComponent(`/attendance/global/analytics-v2?company_id=${companyId}&view=${view}`);

    return buildUrl(
      `/entrypoint?loginRequired=${loginRequired}&redirect_url=${redirectUrl}`,
      config.workplaceAnalyticsHost,
    );
  },
  v1: {
    logoUploadUrl(id: string): string {
      return buildUrl(`/api/v1/locations/${id}`);
    },
    printBadgesUrl(locationId: string): string {
      return buildUrl(`/api/v1/locations/${locationId}/print_badges`);
    },
    welcomeImageUploadUrl(id: string): string {
      return buildUrl(`/api/v1/configs/${id}`);
    },
    generatePreRegistrationLinkUrl(id: string): string {
      return buildUrl(`/api/v1/locations/${id}/generate_pre_registration_link`);
    },
    generateSecurityDeskLinkUrl(id: string): string {
      return buildUrl(`/api/v1/locations/${id}/generate_security_desk_link`);
    },
  },
  v2: {
    visitorsLandingPage(locationId: string): string {
      return buildUrl(`/a/visitors/api/v2/analytics/visitors-landing-page/${locationId}`);
    },
    deliveriesLandingPage(locationId: string): string {
      return buildUrl(`/a/deliveries/api/v2/analytics/deliveries-landing-page/${locationId}`, config.deliveriesHost);
    },
    roomsActiveCountUrl(companyId: string): string {
      return buildUrl(`/a/roomba/billing/status?company_id=${companyId}`, config.roomsHost);
    },
    roomsLandingPage(locationId: string): string {
      return buildUrl(`/a/roomba/analytics/rooms-landing-page/${locationId}`, config.roomsHost);
    },
    roomsOnboardingStatus(locationId: string): string {
      return buildUrl(`/a/roomba/onboarding_status?location_id=${locationId}`, config.roomsHost);
    },
    roomsContactTracingCsv(emails: string[], startDate: string, endDate: string): string {
      const apiBase = 'a/rooms-v2';
      const emailList: string[] = [];
      emails.forEach((email) => {
        emailList.push(encodeURIComponent(email));
      });
      const encodedEmails = emailList.join(',');

      return buildUrl(
        `${apiBase}/contact_tracing?emails=${encodedEmails}&start_time=${startDate}&end_time=${endDate}&location_based=true`,
        config.roomsHost,
      );
    },
    roomsCalendarPermissionsCheck(companyRoomId: string): string {
      return buildUrl(`/a/rms/api/v2/calendar_permissions/${companyRoomId}`);
    },
    entryBulkUrl(): string {
      return buildUrl('/a/visitors/api/v2/entries/bulk-create');
    },
    pubnubChannels(): string {
      return buildUrl('/a/visitors/api/v2/me/pubnub-channels');
    },
    entriesCalendarUrl(locationId: string, date: string): string {
      return buildUrl(`/a/visitors/api/v2/locations/${locationId}/calendar?filter%5Bdate%5D=${date}`);
    },
    emailNotificationPreviewUrl(locationId: string): string {
      return buildUrl(`/a/visitors/api/v2/locations/${locationId}/notification-preview`);
    },
    invitePreviewUrl(locationId: string): string {
      return buildUrl(`/a/visitors/api/v2/locations/${locationId}/invite-preview`);
    },
    sendInvitePreviewUrl(locationId: string): string {
      return buildUrl(`/a/visitors/api/v2/locations/${locationId}/send-invite-preview`);
    },
    generateApiKeyUrl(id: string): string {
      return buildUrl(`/a/visitors/api/v2/locations/${id}/generate-key`);
    },
    updateLocationPhotoUrl(locationId: string): string {
      return buildUrl(`/a/visitors/api/v2/locations/${locationId}/logo`);
    },
    slideshowUploadUrl(): string {
      return buildUrl('/a/visitors/api/v2/slideshows');
    },
    integrations: {
      enabled(): string {
        return buildUrl('/a/visitors/api/v2/integrations/enabled');
      },
      googleAppsUrl(id = ''): string {
        return buildUrl(`/a/visitors/api/v2/integrations/google-apps/${id}`);
      },
      googleAppUsersUrl(id: string): string {
        return buildUrl(`/a/visitors/api/v2/integrations/google-apps/${id}/users`);
      },
      zapierIntegrationsUrl(id = ''): string {
        return buildUrl(`/a/visitors/api/v2/integrations/zapier-integrations/${id}`);
      },
      iftttIntegrationsUrl(id = ''): string {
        return buildUrl(`/a/visitors/api/v2/integrations/ifttt-integrations/${id}`);
      },
      oneLoginUsersUrl(id: string): string {
        return buildUrl(`/a/visitors/api/v2/integrations/one-logins/${id}/users`);
      },
      oneLoginCustomAttributesUrl(id: string): string {
        return buildUrl(`/a/visitors/api/v2/integrations/one-logins/${id}/custom-attributes`);
      },
      oktaUsersUrl(id: string): string {
        return buildUrl(`/a/visitors/api/v2/integrations/okta/${id}/users`);
      },
      oktaCustomAttributesUrl(id: string): string {
        return buildUrl(`/a/visitors/api/v2/integrations/okta/${id}/custom-attributes`);
      },
      oktaGroupsUrl(id: string): string {
        return buildUrl(`/a/visitors/api/v2/integrations/okta/${id}/groups`);
      },
      salesforceIntegrationsUrl(id = ''): string {
        return buildUrl(`/a/visitors/api/v2/integrations/salesforce-integrations/${id}`);
      },
    },
    users: {
      search(): string {
        return buildUrl(`/a/visitors/api/v2/users/lookup`, config.apiOverwrittenHost);
      },
    },
    userRoles(): string {
      return buildUrl('/a/visitors/api/v2/user-roles');
    },
    bulkCreateRoles(): string {
      return buildUrl('/a/visitors/api/v2/role-assignments/bulk');
    },
    bulkDeleteRoles(): string {
      return buildUrl('/a/visitors/api/v2/role-assignments/bulk-destroy');
    },
    assignmentCountsPerRole(): string {
      return buildUrl('/a/visitors/api/v2/role-assignments/count');
    },
    distinctMappingValues(scimPrimaryLocationConfigId: string): string {
      return buildUrl(
        `/a/visitors/api/v2/scim-primary-location-configurations/${scimPrimaryLocationConfigId}/distinct-mapping-values`,
      );
    },
    config: {
      attachmentsUrl(configId: string, type: string): string {
        return buildUrl(`/a/visitors/api/v2/configs/${configId}/attachments/${type}`);
      },
    },
  },
  v3: {
    blocklistFilter: {
      photoUpload(id: string): string {
        return buildUrl(`/a/visitors/api/v3/blacklist-filters/${id}/attachments/photo`);
      },
      uploadCsv(locationId: string, deleteAllFirst: boolean): string {
        return buildUrl(
          `/a/visitors/api/v3/blacklist-filters/upload?location_id=${locationId}&delete_all_first=${deleteAllFirst}`,
        );
      },
      uploadCsvStatus(): string {
        return buildUrl(`/a/visitors/api/v3/blacklist-filters/upload-status`);
      },
    },
    csvUpload(): string {
      return buildUrl('/a/visitors/api/v3/csv-uploads');
    },
    employees: {
      synchronizeUrl(companyId: string): string {
        return buildUrl(`/a/visitors/api/v3/employees/synchronize?company_id=${companyId}`);
      },
      inviteUser(id: string): string {
        return buildUrl(`/a/visitors/api/v3/employees/${id}/invite`);
      },
      uploadUrl(): string {
        return buildUrl('/a/visitors/api/v3/employees/upload');
      },
      uploadStatusUrl(): string {
        return buildUrl('/a/visitors/api/v3/employees/upload-status');
      },
      exportUrl(companyId: string): string {
        return buildUrl(`/a/visitors/api/v3/employees/export?company_id=${companyId}`);
      },
      bulkUrl(): string {
        return buildUrl('/a/visitors/api/v3/employees/bulk_create');
      },
    },
    entry: {
      previousEntriesUrl(id: string): string {
        return buildUrl(`/a/visitors/api/v3/entries/${id}/previous-entries`);
      },
    },
    groupInvites: {
      sendNotification(id: string): string {
        return buildUrl(`/a/visitors/api/v3/group-invites/${id}/notify`);
      },
    },
    invites: {
      bulkUrl(): string {
        return buildUrl('/a/visitors/api/v3/invites/bulk');
      },
      sendNotification(id: string): string {
        return buildUrl(`/a/visitors/api/v3/invites/${id}/notify`);
      },
      fetchEmployeeInviteOnDate(filter: FetchEmployeeInviteOnDateFilter): string {
        return buildUrl(
          `/a/visitors/api/v3/invites?filter[location]=${filter.locationId}&filter[email]=${filter.email}&filter[employee-centric]=true&filter[datetime-from]=${filter.dateFrom}&filter[datetime-to]=${filter.dateTo}`,
        );
      },
    },
    recurringInvites: {
      sendNotification(id: string): string {
        return buildUrl(`/a/visitors/api/v3/recurring-invites/${id}/notify`);
      },
    },
    salesforceLeads: {
      createUrl(): string {
        return buildUrl('/a/visitors/api/v3/salesforce-leads');
      },
    },
    scimDataAutocomplete(term: string, limit: number): string {
      return buildUrl(`/a/visitors/api/v3/scim-data?filter[name]=${term}&filter[limit]=${limit}`);
    },
    placesAutocomplete(term: string): string {
      return buildUrl(`/a/visitors/api/v3/places/autocomplete?filter[search]=${term}`);
    },
    summaryPageImageUpload(id: string): string {
      return buildUrl(`/a/visitors/api/v3/summary-pages/${id}/attachments/image`);
    },
    sendTestNotificationUrl(): string {
      return buildUrl('/a/visitors/api/v3/sign-in-field-action-rule-groups/send-test-notification');
    },
    userDocuments: {
      approveUserDocumentLink(id: string): string {
        return buildUrl(`/a/visitors/api/v3/user-document-links/${id}/approve`);
      },
      denyUserDocumentLink(id: string): string {
        return buildUrl(`/a/visitors/api/v3/user-document-links/${id}/deny`);
      },
      resetUserDocumentLink(id: string): string {
        return buildUrl(`/a/visitors/api/v3/user-document-links/${id}/review`);
      },
    },
    users: {
      acceptPrivacyPolicy(): string {
        return buildUrl(`/a/visitors/api/v3/users/accept_privacy_policy`);
      },
    },
    printers: {
      badgePrintJobs(): string {
        return buildUrl(`/a/visitors/api/v3/badge-print-jobs`);
      },
    },
    getCompanyConfigurationUrl(companyId: string): string {
      return buildUrl(`/a/visitors/api/v3/companies/${companyId}/company-configuration`);
    },
  },
  hooks: {
    v1: {
      triggerZoneUiHooksUrl(): string {
        return buildUrl(`/a/hooks/v1/zones/hooks/ui/trigger`, config.hooksHost);
      },
    },
  },
  roomba: {
    getAvailableRooms(locationId: string): string {
      return buildUrl(
        `/a/roomba/json-api/rooms?filter[location]=${locationId}&filter[available_only]=false`,
        config.apiHost,
      );
    },
    googleAuthUrl(): string {
      return buildUrl(`/a/roomba/auth/google`, config.roomsHost);
    },
    microsoftAuthUrl(): string {
      return buildUrl(`/a/roomba/auth/microsoft/service`, config.roomsHost);
    },
    microsoftOfficeUrl(): string {
      return buildUrl(`/a/roomba/auth/microsoft/user`, config.roomsHost);
    },
  },
  rms: {
    bulkDesksUpdateEndpoint(includeAssignments: boolean = false): string {
      const baseUrl = includeAssignments
        ? `/a/rms/v2/generics/resources/bulk-update?include=assignments`
        : `/a/rms/v2/generics/resources/bulk-update`;
      return buildUrl(baseUrl, config.rmsHost);
    },
    bulkDesksCreateEndpoint(includeAssignments: boolean = false): string {
      const baseUrl = includeAssignments
        ? `/a/rms/v2/generics/resources/bulk-create?include=assignments`
        : `/a/rms/v2/generics/resources/bulk-create`;
      return buildUrl(baseUrl, config.rmsHost);
    },
    bulkDesksDeleteEndpoint(): string {
      return buildUrl(`/a/rms/v2/generics/resources/bulk-delete`, config.rmsHost);
    },
    googleAuthUrl(companyId: string = ''): string {
      return buildUrl(`/a/rms/auth/google?company_id=${companyId}`, config.rmsHost);
    },
    microsoftAuthUrl(companyId: string = ''): string {
      return buildUrl(`/a/rms/auth/microsoft?company_id=${companyId}`, config.rmsHost);
    },
    microsoftOfficeUrl(companyId: string = ''): string {
      return buildUrl(`/a/rms/auth/microsoft?company_id=${companyId}`, config.rmsHost);
    },
    floorplanUrl(floorId: string): string {
      return buildUrl(`/a/rms/floors/${floorId}/floor-plan`, config.rmsHost);
    },
    uploadDesksCsv(locationId: string): string {
      return buildUrl(`/a/rms/desks/upload-desks-per-location?location_id=${locationId}`, config.rmsHost);
    },
    downloadDesksCsv(locationId: string): string {
      return buildUrl(`/a/rms/desks/download-desks?filter[location-id]=${locationId}`, config.rmsHost);
    },
    getDesksInNeighborhood(neighborhoodId: string, locationId: string): string {
      return buildUrl(
        `a/rms/desks?filter[location-id]=${locationId}&filter[neighborhood-id]=${neighborhoodId}`,
        config.rmsHost,
      );
    },
    reservation(entryId: string): string {
      return buildUrl(`/a/rms/reservations?filter[entry-id]=${entryId}&include=desk`, config.rmsHost);
    },
    bulkCreateReservations(): string {
      return buildUrl(`/a/rms/bulk-reservations`, config.rmsHost);
    },
    getDeskAvailability(): string {
      return buildUrl(`/a/rms/available-desks`, config.rmsHost);
    },
    sendAnnouncementNotification(announcementId: string): string {
      return buildUrl(`/a/rms/announcements/${announcementId}`, config.rmsHost);
    },
    releaseDesk(): string {
      return buildUrl(`/a/rms/desks/abstract/release-desk`, config.rmsHost);
    },
    bulkEntryCheckIn(): string {
      return buildUrl(`/a/rms/desks/abstract/bulk-entry-check-in`, config.rmsHost);
    },
  },
  connect: {
    v1: {
      reorderFloors(propertyId: string): string {
        return buildUrl(`/a/multi-tenancy/api/v1/connect-properties/${propertyId}/reorder-floors`, config.apiHost);
      },
    },
  },
  multiTenancy: {
    /**
     *
     * @param {string} token  a token
     * @returns {string} url
     */
    claimToken(token: string): string {
      return buildUrl(`/a/multi-tenancy/api/v1/tenant-connection-tokens/${token}/claim`, config.apiHost);
    },
  },
  maps: {
    bulkFeaturesCreateEndpoint(): string {
      return buildUrl(`/a/maps/api/v2/map-features/bulk-create`, config.mapsHost);
    },
    bulkFeaturesUpdateEndpoint(): string {
      return buildUrl(`/a/maps/api/v1/map-features/bulk-update`, config.mapsHost);
    },
    bulkFeaturesDeleteEndpoint(): string {
      return buildUrl(`/a/maps/api/v2/map-features/bulk-delete`, config.mapsHost);
    },
    rasterImageUrl(mapFloorId: string): string {
      return buildUrl(`/a/maps/api/v1/map-floors/${mapFloorId}/raster-image`, config.mapsHost);
    },
  },
  growth: {
    getGrowthServiceUrl(): string {
      return buildUrl('/a/growth/api/v1/', config.growthApiHost);
    },
    getThinkificSsoUrl(): string {
      return buildUrl('/a/growth/api/v1/thinkific/sso', config.growthApiHost);
    },
  },
  authz: {
    getPermissionsUrl(companyId: string): string {
      return buildUrl(`/a/authz/api/v1/c/${companyId}/ui-permissions`, config.authzHost);
    },
    getPermissionSetsUrl(): string {
      return buildUrl(`/a/authz/api/v1/permission-sets`, config.authzHost);
    },
    getMatrixUrl(
      companyId: string,
      roleId: string,
      subscription: { hasVisitorsAccess?: boolean; hasWorkplaceAccess?: boolean } = {},
    ): string {
      const { hasVisitorsAccess = false, hasWorkplaceAccess = false } = subscription;
      return buildUrl(
        `/a/authz/api/v1/c/${companyId}/roles/${roleId}/matrix?hasVisitorsAccess=${hasVisitorsAccess}&hasWorkplaceAccess=${hasWorkplaceAccess}`,
        config.authzHost,
      );
    },
    getRolesUrl(companyId: string): string {
      return buildUrl(`/a/authz/api/v1/c/${companyId}/roles`, config.authzHost);
    },
    getRoleUrl(companyId: string, roleId: string): string {
      return buildUrl(`/a/authz/api/v1/c/${companyId}/roles/${roleId}`, config.authzHost);
    },
  },
  slack: {
    v2: {
      searchChannelsUrl(pluginInstallId: string): string {
        return buildUrl(`/a/slack-plugin-v2/private/chat-bot/${pluginInstallId}/channels`, config.slackPluginHost);
      },
    },
  },
  teams: {
    v2: {
      searchChannelsUrl(pluginInstallId: string): string {
        return buildUrl(`/a/msteams-plugin-v2/private/chat-bot/${pluginInstallId}/channels`, config.teamsPluginHost);
      },
    },
  },
};
