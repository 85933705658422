import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { localCopy } from 'tracked-toolbox';

/**
 *
 * @param {String}          triggerClass
 * @param {String}          dropdownClass
 * @param {String}          viewPortSelector
 * @param {Array}           options
 * @param {Function}        setSelectedOptions
 * @param {String}          selectedOptions
 * @param {String}          noOptionSelectedText
 * @param {String}          optionAttr
 * @param {Boolean}         hasMorePages
 * @param {Boolean}         searchEnabled
 * @param {Task}            searchTask
 * @param {Task}            loadMoreTask
 */
export default class InfiniteLoadMultiSelectDropdown extends Component {
  @tracked _EPSapi;
  @localCopy('args.selectedOptions') selectedOptions;

  @action
  onClose(_, event) {
    if (event.uniqueId) {
      return false;
    }
    return true;
  }

  @action
  selectOption(option) {
    const { setSelectedOptions } = this.args;
    if (this.selectedOptions.includes(option)) {
      // Deseleting an option
      this.selectedOptions = this.selectedOptions.filter((selected) => selected !== option);
    } else {
      this.selectedOptions = [...this.selectedOptions, option];
    }
    setSelectedOptions(this.selectedOptions);
  }

  @action
  onDeselectAll() {
    this.args.setSelectedOptions([]);
  }

  @action
  onSelectAll() {
    this.args.setSelectedOptions(this.args.options);
  }

  @action
  registerPowerSelectAPI(publicAPI) {
    this._EPSapi = publicAPI;
  }
}
