import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class LocationOverviewGlobalDevicesController extends Controller {
  queryParams = ['showList'];
  @tracked showList = true;

  @service router;

  get showNav() {
    return !this.router.currentRouteName.includes('virtual-front-desk');
  }
}
