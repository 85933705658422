import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AnnouncementModel from 'garaje/models/announcement';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';

export interface CommunicationsMessageParams {
  announcement_id: string;
}

export interface CommunicationsMessageModel {
  announcement: AnnouncementModel;
  announcementId: string;
}

export default class CommunicationsMessageRouteBase extends Route {
  @service declare store: Store;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare transitionConfirm: TransitionConfirmService;

  async model({ announcement_id }: CommunicationsMessageParams): Promise<CommunicationsMessageModel | void> {
    try {
      const announcement = await this.store.findRecord('announcement', announcement_id, {
        adapterOptions: {
          fields: {
            announcements:
              'id,created-at,title,message,emergency-notification,statistics,creator-employee,location,announcement-channels,announcement-audiences',
          },
          v3: true,
        },
        include: 'emergency-notification,announcement-channels,creator-employee,location,announcement-audiences',
        reload: true,
      });

      if (!announcement) {
        return void this.router.transitionTo('communications.message-log');
      }

      return {
        announcement,
        announcementId: announcement_id,
      };
    } catch (_error) {
      void this.router.transitionTo('unauthorized');
    }
  }
}
