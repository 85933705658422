import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';

const dateFormat = 'YYYYMMDDHHmmss';

interface RecurringRuleOBJ {
  FREQ: string;
  BYDAY: string;
  BYMONTH: string;
  BYMONTHDAY: string;
  UNTIL: string;
}

export default class RecurringRule {
  @tracked frequency: string | null = null;
  @tracked byDay: string | null = null;
  @tracked byMonth: string | null = null;
  @tracked byMonthDay: string | null = null;
  @tracked until: moment.Moment | null = null;

  constructor(args = {}) {
    Object.assign(this, args);
  }

  formatUntil(date: moment.Moment): string {
    const formattedDate = moment.utc(moment(date).endOf('date').subtract(59, 'seconds')).format(dateFormat);
    return formattedDate + 'Z';
  }

  isBlank(): boolean {
    return this.toString() === '';
  }

  toString(): string {
    let string = '';

    if (this.frequency) {
      string = string + `FREQ=${this.frequency};`;
    }
    if (this.byDay) {
      string = string + `BYDAY=${this.byDay};`;
    }
    if (this.byMonth) {
      string = string + `BYMONTH=${this.byMonth};`;
    }
    if (this.byMonthDay) {
      string = string + `BYMONTHDAY=${this.byMonthDay};`;
    }
    if (this.until) {
      string = string + `UNTIL=${this.formatUntil(this.until)};`;
    }

    return string;
  }

  static parse(rruleStr: string): RecurringRule {
    const rruleObj = (rruleStr || '').split(';').reduce<Partial<RecurringRuleOBJ>>((acc, elem) => {
      const arr = <Array<keyof RecurringRuleOBJ>>elem.split('=');
      acc[arr[0]!] = arr[1];
      return acc;
    }, {});

    return new RecurringRule({
      frequency: rruleObj['FREQ'],
      byDay: rruleObj['BYDAY'],
      byMonth: rruleObj['BYMONTH'],
      byMonthDay: rruleObj['BYMONTHDAY'],
      until: rruleObj['UNTIL'] ? moment(rruleObj['UNTIL'], dateFormat + 'Z') : null,
    });
  }
}
