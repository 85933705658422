import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
import ObjectProxy from '@ember/object/proxy';
import { A } from '@ember/array';

/**
 * All of this complexity is because we cannot save a mailer-template-version relationships via a flow
 * This ObjectProxy computes the selected, saved and default versions for a given flow and provides
 * information about which versions need to be saved when the selected version is updated
 */
class FlowProxy extends ObjectProxy {
  @tracked mailerTemplateVersions;
  @tracked _selectedVersion;

  get defaultVersion() {
    return this.mailerTemplateVersions.findBy('isDefault', true);
  }

  get savedVersion() {
    const { defaultVersion, mailerTemplateVersions } = this;
    const matchedVersion = mailerTemplateVersions.find((mailerTemplateVersion) =>
      isPresent(mailerTemplateVersion.flows.findBy('id', this.content.id)),
    );
    return matchedVersion || defaultVersion;
  }

  get selectedVersion() {
    return this._selectedVersion || this.savedVersion || this.defaultVersion;
  }

  set selectedVersion(version) {
    this._selectedVersion = version;
  }

  get isDirty() {
    return this.selectedVersion !== this.savedVersion;
  }

  updateRelationships() {
    const { selectedVersion, savedVersion, isDirty, content } = this;
    const updatedVersions = A();
    if (isDirty) {
      if (savedVersion) {
        savedVersion.flows.removeObject(content);
        updatedVersions.addObject(savedVersion);
      }
      if (selectedVersion) {
        selectedVersion.flows.addObject(content);
        updatedVersions.addObject(selectedVersion);
      }
    }
    return updatedVersions;
  }
}

export default class ManageRecipientsModalComponent extends Component {
  @tracked flows;

  constructor() {
    super(...arguments);
    const { flows, mailerTemplateVersions } = this.args;
    this.flows = flows.map((flow) => FlowProxy.create({ mailerTemplateVersions, content: flow }));
  }

  get hasDirtyFlows() {
    return isPresent(this.flows.findBy('isDirty'));
  }

  @action
  onChange(flow, version) {
    set(flow, 'selectedVersion', version);
  }

  @dropTask
  *saveRecipientsTask() {
    const { flows } = this;
    const { onSave } = this.args;
    const versionsToSave = flows.reduce((acc, flow) => acc.addObjects(flow.updateRelationships()), A());
    yield onSave(versionsToSave);
  }
}
