import { Select, SelectOption } from '@envoy/polarwind-react';
import { ComponentProps, ReactNode } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';

export interface FormSelectProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  options: SelectOption[];
  required?: string;
  disabled?: boolean;
  dropdownAction?: ComponentProps<typeof Select>['dropdownAction'];
  preselectValue?: boolean;
  placeholder?: string;
  emptyNode?: ReactNode;
  testId?: string;
  tooltip?: string;
}

export function FormSelect<T extends FieldValues>(props: FormSelectProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({ name: props.name, rules: { required: props.required } });

  return (
    <Select
      label={props.label}
      required={!!props.required}
      disabled={props.disabled}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      dropdownAction={props.dropdownAction}
      options={props.options}
      onChange={(value: string) => field.onChange(value)}
      value={(Array.isArray(field.value) ? field.value[0] : field.value) as FieldValues}
      error={error?.message}
      preselectValue={props.preselectValue}
      placeholder={props.placeholder}
      emptyNode={props.emptyNode}
      testId={props.testId}
      tooltip={props.tooltip}
      key={JSON.stringify(props.options)}
    />
  );
}
