import Controller from '@ember/controller';
import { action, get, set, defineProperty } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import ColorThief from 'color-thief';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import type { ValidationErr } from 'ember-changeset-validations';
import lookupValidator from 'ember-changeset-validations';
import type { TaskInstance } from 'ember-concurrency';
import { restartableTask, dropTask } from 'ember-concurrency';
import { optOutDefaultMessage } from 'garaje/models/abstract/abstract-gdpr-configuration';
import type GdprConfigurationModel from 'garaje/models/gdpr-configuration';
import type LocationModel from 'garaje/models/location';
import type AddressLookupService from 'garaje/services/address-lookup';
import type AsyncExportManagerService from 'garaje/services/async-export-manager';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type GlobalSettingBatchService from 'garaje/services/global-setting-batch';
import type MessageBusService from 'garaje/services/message-bus';
import type SetupGuideStepperService from 'garaje/services/setup-guide-stepper';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import { APP } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import LOCALE_OPTIONS, { disabledLocalesInfo, localeUpdateWarning } from 'garaje/utils/locale-options';
import manualPropagableHandler from 'garaje/utils/manual-propagable-handler';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import LocationValidations from 'garaje/validations/location';
import type { SinglePayload } from 'jsonapi/response';
import { alias, or } from 'macro-decorators';
import { reject } from 'rsvp';

import type { LocationSettingsIndexRouteModel } from './route';

const CONFIRM_DEACTIVATE_VISITORS_LOCATION_MESSAGE =
  'Are you sure you want to deactivate Visitors at this location? All iPads at this location will be disabled.';

const CONFIRM_DEACTIVATE_VISITORS_LOCATION_MESSAGE_WITH_VFD =
  'Are you sure you want to deactivate Visitors at this location? Visitors will no longer be able to sign in or out at this location.';

const CONFIRM_DISABLE_LOCATION_MESSAGE =
  'Are you sure you want to disable this location? All functionality will be disabled for this location except to Global Admins.';

const componentToHex = function (c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const rgbToHex = function (r: number, g: number, b: number) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

export const ScrollComponents = Object.freeze({
  EMERGENCY_NOTIFICATIONS: 'emergency-notifications',
});

export default class LocationSettingsIndexController extends Controller {
  declare model: LocationSettingsIndexRouteModel;

  @service declare addressLookup: AddressLookupService;
  @service declare asyncExportManager: AsyncExportManagerService;
  @service declare flashMessages: FlashMessagesService;
  @service('global-setting-batch') declare globalSettingBatchService: GlobalSettingBatchService;
  @service declare messageBus: MessageBusService;
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;
  @service declare featureFlags: FeatureFlagsService;
  @service('setup-guide-stepper') declare setupGuideStepperService: SetupGuideStepperService;
  @service declare state: StateService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  declare disabledLocalesInfo: {
    disabledLocales: Array<{ localeCode: string }>;
    appVersionNeeded: string;
  };

  queryParams = ['scrollTo'];

  inputAddress = null;
  @tracked apiKeyCallback: unknown = null;
  @tracked changeset!: DetailedChangeset<LocationModel>;
  @tracked tenants!: this['model']['tenants'];
  @tracked scrollTo = null;
  @tracked _accentColorHex: string | null = null;

  @alias('model.vrSubscription') currentSubscription!: this['model']['vrSubscription'];
  @alias('globalSettingBatchService.currentGlobalSettingBatch')
  globalSettingBatch!: GlobalSettingBatchService['currentGlobalSettingBatch'];
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin!: boolean;

  get accentColorHex(): string {
    return this._accentColorHex || this.model.currentLocation.color.substring(1);
  }

  set accentColorHex(color: string) {
    this._accentColorHex = color;
  }

  get showAutoSignInConfig(): boolean {
    return Boolean(
      this.model.nonDisconnectedTenants?.length &&
        this.model.connectLocationConfiguration &&
        this.featureFlags.isEnabled('property-management-auto-sign-in'),
    );
  }

  constructor(properties: Record<string, unknown>) {
    super(properties);
    defineProperty(this, 'localeUpdateWarning', localeUpdateWarning());
    defineProperty(this, 'disabledLocalesInfo', disabledLocalesInfo('model.ipads'));
  }

  get showCapacitySettings(): boolean {
    return Boolean(
      this.state.visitorsSubscription?.canAccessCapacityLimits ||
        this.state.workplaceSubscription?.canAccessCapacityLimits,
    );
  }

  get isDirty(): boolean {
    const { currentLocation } = this.model;
    const gdprConfig = currentLocation.gdprConfiguration;
    // if there is an error, the form is dirty because user
    // is unaware of the change they made is not saving
    const errors = this.changeset.errors;

    if (
      errors.length > 0 ||
      // eslint-disable-next-line ember/no-get
      get(currentLocation, 'company.hasDirtyAttributes') ||
      // eslint-disable-next-line ember/no-get
      get(gdprConfig, 'hasDirtyAttributes')
    ) {
      return true;
    }

    return (
      (this.changeset.isDirty || <boolean>(<unknown>currentLocation.hasDirtyAttributes)) &&
      !currentLocation.isOnlyEmployeesCSVUploadStatusDirty
    );
  }

  get isColorChanged(): boolean {
    return 'color' in this.model.currentLocation.changedAttributes();
  }

  _buildChangeset(model: LocationModel): void {
    const validator = lookupValidator(LocationValidations);
    this.changeset = Changeset(model, validator, LocationValidations);
  }

  get localeOptions(): Array<{ label: string; value: string }> {
    if (!this.disabledLocalesInfo) {
      return LOCALE_OPTIONS;
    }
    const { disabledLocales } = this.disabledLocalesInfo;
    const disabledLocaleCodes = new Set(disabledLocales.map((locale) => locale.localeCode));
    return LOCALE_OPTIONS.map(({ value, label }) => {
      const disabled = disabledLocaleCodes.has(value);
      return { value, label, disabled };
    });
  }

  get showSetupGuide(): boolean {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  deleteLogoTask = dropTask(async () => {
    this.model.currentLocation.logo = null;
    try {
      await this.model.currentLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      if (this.visitorsOnboarding.showVideoWalkthrough) {
        void this.visitorsOnboarding.loadLocationsTask.perform(true);
      }
    } catch (e) {
      this.model.currentLocation.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  deactivateVisitorsTask = dropTask(async () => {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_DEACTIVATE_VISITORS_LOCATION_BUTTON_CLICKED');
    const vfdConfiguration = await this.model.currentLocation?.getVfdConfiguration();
    // VFD related message only for M1
    const message =
      !this.featureFlags.isEnabled('growth_vfd_without_visitors') && vfdConfiguration?.enabled
        ? CONFIRM_DEACTIVATE_VISITORS_LOCATION_MESSAGE_WITH_VFD
        : CONFIRM_DEACTIVATE_VISITORS_LOCATION_MESSAGE;
    if (!window.confirm(message)) {
      return;
    }

    set(this.model.currentLocation, 'disabled', true);

    try {
      await this.model.currentLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.model.currentLocation.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  reactivateVisitorsTask = dropTask(async () => {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_LOCATION_REACTIVATED_VISITORS');
    set(this.model.currentLocation, 'disabled', false);

    try {
      await this.model.currentLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.model.currentLocation.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  disableLocationTask = dropTask(async () => {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_DISABLE_LOCATION_BUTTON_CLICKED');
    if (!window.confirm(CONFIRM_DISABLE_LOCATION_MESSAGE)) {
      return;
    }

    try {
      const location = await this.model.currentLocation.skinnyLocation;
      await location.disableLocation();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      return; // early return to avoid setting local model
    }
    this.model.currentLocation.disabledToEmployeesAt = new Date(Date.now());
    try {
      await this.model.currentLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.model.currentLocation.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  enableLocationTask = dropTask(async () => {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_LOCATION_ENABLED');

    try {
      const location = await this.model.currentLocation.skinnyLocation;
      await location.enableLocation();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      return; // early return to avoid setting local model
    }
    this.model.currentLocation.disabledToEmployeesAt = null;
    try {
      await this.model.currentLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.model.currentLocation.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  saveLocationTask = dropTask(async () => {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_ADDRESS_UPDATED');
    try {
      await this.validateAndUpdateAddressTask.perform();
      await this.changeset.save();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  setDataPrivacySetting(value: boolean): GdprConfigurationModel {
    const { currentLocation } = this.model;
    let gdprConfig = currentLocation.gdprConfiguration.content;

    if (!gdprConfig) {
      // create a record if there are none
      gdprConfig = this.store.createRecord('gdpr-configuration', {
        optOutVisitorProtocolEnabled: true,
        optOutVisitorProtocol: optOutDefaultMessage,
        location: currentLocation,
      });
      currentLocation.gdprConfiguration.content = gdprConfig;
    }
    gdprConfig.enabled = value;

    return gdprConfig;
  }

  setDataPrivacySettingTask = dropTask(async () => {
    try {
      await this.model.currentLocation.gdprConfiguration.content?.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  setColorTask = dropTask(async () => {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_UPDATE_ACCENT_COLOR');
    this.model.currentLocation.color = `#${this.accentColorHex}`;

    if (!this.isColorChanged) {
      return;
    }

    try {
      await this.model.currentLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      if (this.visitorsOnboarding.showVideoWalkthrough) {
        void this.visitorsOnboarding.loadLocationsTask.perform(true);
      }
    } catch (e) {
      this.model.currentLocation.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  validateAndUpdateAddressTask = restartableTask(async () => {
    try {
      await this.addressLookup.updateAddress(this.changeset.address, this.changeset);
    } catch (error) {
      this.changeset.addError('address', <ValidationErr>error);
      await reject(error);
    }
  });

  async detectImageColor(imagePromise: Promise<HTMLImageElement>): Promise<void> {
    const image = await imagePromise;
    const colorThief = new ColorThief();
    const dominantColor = colorThief.getColor(image);
    const palette = colorThief.getPalette(image, 5);
    const dominantHex = rgbToHex(dominantColor[0], dominantColor[1], dominantColor[2]);
    const actualColors: string[] = [];

    palette.forEach(function (color) {
      const difference1 = Math.abs(color[0] - color[1]);
      const difference2 = Math.abs(color[1] - color[2]);
      const difference3 = Math.abs(color[0] - color[2]);
      const differenceSum = difference1 + difference2 + difference3;

      if (differenceSum > 50) {
        const hexConvert = rgbToHex(color[0], color[1], color[2]);
        actualColors.push(hexConvert);
      }
    });

    if (actualColors.length > 0) {
      this.model.currentLocation.color = actualColors[0]!;
    } else {
      this.model.currentLocation.color = dominantHex;
    }
    void this.model.currentLocation.save();
  }

  @action
  async connectionsChanged(): Promise<void> {
    this.tenants = await this.store.query('tenant', {
      filter: {
        location: this.model.currentLocation.id,
      },
    });

    await this.skinnyLocations.loadAllTask.perform(true).catch(throwUnlessTaskDidCancel);
  }

  @action
  expandSlideshow(): void {
    void this.router.transitionTo('visitors.settings.welcome-screen.design').then(() => {
      next(this, function () {
        this.messageBus.trigger('expandSlideshow');
      });
    });
  }

  @action
  expandWelcomeImage(): void {
    void this.router.transitionTo('visitors.settings.welcome-screen.design').then(() => {
      // allow messageBus to install first on the component
      // before triggering
      next(this, function () {
        this.messageBus.trigger('expandWelcomeImage');
      });
    });
  }

  @action
  exportEntries(): TaskInstance<void> {
    const params = { location: this.model.currentLocation, sort: '-signed-in-at' };
    return this.asyncExportManager.exportEntriesTask.perform(params);
  }

  @action
  setLogo({ data }: SinglePayload<LocationModel>, promise: Promise<HTMLImageElement>): void {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_LOGO_UPLOADED');
    this.store.push({
      data: {
        type: 'location',
        id: data.id,
        attributes: {
          logo: data.attributes.logo,
        },
      },
    });
    void this.detectImageColor(promise);
    manualPropagableHandler(this.globalSettingBatch, 'location', 'logo');
    if (this.visitorsOnboarding.showVideoWalkthrough) {
      void this.visitorsOnboarding.loadLocationsTask.perform(true);
    }

    if (this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper')) {
      void this.setupGuideStepperService.loadSetupStepsTask.perform(APP.VISITORS);
    }
  }

  @action
  regenerateApiKey(): void {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_API_KEY_REGENERATED');
    if (
      window.confirm(
        'Are you sure you want to regenerate the API key? Existing API clients will unable to access Envoy.',
      )
    ) {
      this.apiKeyCallback = this.model.currentLocation.generateApiKey();
    }
  }

  @action
  selectPlan(callback: (callback: Transition<unknown>) => void, plan: string, period: string): void {
    const params = { queryParams: { plan, period } };
    callback(this.router.transitionTo('billing.subscribe', params));
  }

  @action
  rollback(): void {
    const currentLocation = this.model.currentLocation;
    const config = currentLocation.gdprConfiguration.content;
    currentLocation.rollbackAttributes();
    currentLocation.company.content?.rollbackAttributes();
    this.changeset.rollback();
    if (config) {
      config.rollbackAttributes();
    }
  }

  @action
  transitionTo(route = ''): void {
    void this.router.transitionTo(route);
  }

  get showPlaceholderPage(): boolean {
    const currentRouteName = this.router.currentRouteName;
    const isLegacyRoute = !currentRouteName?.startsWith('manage.location-settings');

    return isLegacyRoute;
  }
}
