import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { differenceInDays } from 'date-fns';
import type AbilitiesService from 'ember-can/services/abilities';
import { task, waitForProperty } from 'ember-concurrency';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type EntryModel from 'garaje/models/entry';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionModel from 'garaje/models/subscription';
import type GrowthFeatureSetupService from 'garaje/services/growth-feature-setup';
import type ImpressionsService from 'garaje/services/impressions';
import type LocalStorageService from 'garaje/services/local-storage';
import type SetupGuideStepsService from 'garaje/services/setup-guide-steps';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
import { findAllDeliveryAreasForLocation } from 'garaje/utils/delivery-area';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { type RecordArray } from 'garaje/utils/type-utils';
import { alias, and, or, bool } from 'macro-decorators';

const TRIAL_DAYS_LEFT_OFFSET = 5;
const TRIAL_EXPIRED_DAYS_OFFSET = 14;

interface EntriesResponse extends RecordArray<EntryModel> {
  meta: {
    'has-entries': boolean;
  };
}

export default class HeroContainer extends Component {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare store: Store;
  @service declare setupGuideSteps: SetupGuideStepsService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;
  @service declare impressions: ImpressionsService;
  @service declare growthFeatureSetup: GrowthFeatureSetupService;
  @service declare statsig: StatsigService;
  @service declare localStorage: LocalStorageService;

  @tracked isHeroClosed: boolean = true;
  @tracked showVisitorsStats: boolean = false;
  @tracked showWorkplaceStats: boolean = false;
  @tracked showDeliveriesStats: boolean = false;
  @tracked roomsStepsComplete: boolean = false;

  @alias('state.vrSubscription') declare vrSubscription: StateService['vrSubscription'];
  @alias('state.workplaceSubscription') declare workplaceSubscription: StateService['workplaceSubscription'];
  @alias('state.realDeliveriesSubscription')
  declare realDeliveriesSubscription: StateService['realDeliveriesSubscription'];
  @alias('state.realRoomsSubscription') declare realRoomsSubscription: StateService['realRoomsSubscription'];

  @alias('state.currentLocation') declare location: LocationModel;

  @bool('state.billingCompany.resellerPartner') declare isResellerPartner: boolean;

  @or('showVisitorsStats', 'showWorkplaceStats') declare showStats: boolean;
  @and('showStats', 'hasUsableSubscription') declare showLocationOverview: boolean;

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  constructor(owner: unknown, args: {}) {
    super(owner, args);
    void this.fetchDataToConditionallyRenderTask.perform();
  }

  get isBillingAdmin(): boolean {
    return this.abilities.can('visit billing');
  }

  get hasUsableSubscription(): boolean {
    return !(
      (isEmpty(this.vrSubscription) || this.vrSubscription?.cancelled) &&
      (isEmpty(this.workplaceSubscription) || this.workplaceSubscription?.cancelled) &&
      (isEmpty(this.realDeliveriesSubscription) || this.realDeliveriesSubscription?.cancelled) &&
      (isEmpty(this.realRoomsSubscription) || this.realRoomsSubscription?.cancelled)
    );
  }

  get showHero(): boolean {
    return !this.isHeroClosed && this.subscription !== null && !this.isResellerPartner;
  }

  get subscription(): LocationSubscriptionModel | SubscriptionModel | null {
    const currentDate = new Date();
    let subscription = null;

    if (
      this.vrSubscription &&
      ((this.vrSubscription.onTrial && this.vrSubscription.trialDaysLeft <= TRIAL_DAYS_LEFT_OFFSET) ||
        (this.vrSubscription.onExpiredTrial &&
          differenceInDays(currentDate, this.vrSubscription.trialEndDate) <= TRIAL_EXPIRED_DAYS_OFFSET))
    ) {
      subscription = this.vrSubscription;
    }

    if (
      this.workplaceSubscription &&
      ((this.workplaceSubscription.onTrial && this.workplaceSubscription.trialDaysLeft <= TRIAL_DAYS_LEFT_OFFSET) ||
        (this.workplaceSubscription.onExpiredTrial &&
          differenceInDays(currentDate, this.workplaceSubscription.trialEndDate) <= TRIAL_EXPIRED_DAYS_OFFSET)) &&
      ((subscription !== null && this.showWorkplaceStats && !this.showVisitorsStats) || subscription === null)
    ) {
      subscription = this.workplaceSubscription;
    }

    return subscription;
  }

  @action
  logEvent(eventName: string, eventValue: string | null): void {
    this.statsig.logEvent(eventName, eventValue);
  }

  fetchDataToConditionallyRenderTask = task({ drop: true }, async () => {
    const closedImpressions = await this.impressions.getImpressions.perform(IMPRESSION_NAMES.DASHBOARD.HERO_CLOSED);

    if (this.setupGuideSteps.loadStepsTask.isRunning) {
      await waitForProperty(this.setupGuideSteps.loadStepsTask, 'isIdle', true);
    } else {
      await this.setupGuideSteps.loadStepsTask.perform();
    }

    await this.showVisitorsStatsTask.perform();
    await this.showRoomsStatsTask.perform();
    await this.showDeliveriesStatsTask.perform();

    this.isHeroClosed = !!(closedImpressions && closedImpressions.length > 0);
  });

  showVisitorsStatsTask = task({ drop: true }, async () => {
    const showVRStats = this.localStorage.getItem(`show_vr_stats_${this.location.id}`) === 'true';

    if (showVRStats) {
      this.showVisitorsStats = true;
    } else {
      const featureSetupData = await this.growthFeatureSetup.getSetupTask.perform(
        'invite_created,ipad_paired,employee_added',
      );

      if (isPresent(featureSetupData)) {
        this.showVisitorsStats = isPresent(featureSetupData);
      } else {
        const filter = { location: this.location.id };
        const entries = <EntriesResponse>await this.store.query('entry', { filter, page: { limit: 1 } });
        const invites = <PaginatedRecordArray<InviteModel>>(
          await this.store.query('invite', { filter, page: { limit: 1 } })
        );

        this.showVisitorsStats = entries.meta['has-entries'] || invites.meta.total > 0;
      }

      this.localStorage.setItem(`show_vr_stats_${this.location.id}`, this.showVisitorsStats);
    }
  });

  // eslint-disable-next-line @typescript-eslint/require-await
  showRoomsStatsTask = task({ drop: true }, async () => {
    const showRMSStats = this.localStorage.getItem(`show_rms_stats_${this.location.id}`) === 'true';

    if (showRMSStats) {
      this.roomsStepsComplete = true;
    } else {
      this.roomsStepsComplete =
        this.setupGuideSteps.numberRoomsStepsNotCompleted < this.setupGuideSteps.roomsSteps.length;

      this.localStorage.setItem(`show_rms_stats_${this.location.id}`, this.roomsStepsComplete);
    }

    this.showWorkplaceStats = this.showWorkplaceStats || this.roomsStepsComplete;
  });

  // eslint-disable-next-line @typescript-eslint/require-await
  showDeliveriesStatsTask = task({ drop: true }, async () => {
    const showDLVStats = this.localStorage.getItem(`show_dlv_stats_${this.location.id}`) === 'true';

    if (showDLVStats) {
      this.showDeliveriesStats = true;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const deliveryAreas = await findAllDeliveryAreasForLocation(this.store, this.location);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (deliveryAreas?.length > 0) {
        this.showDeliveriesStats = this.setupGuideSteps.deliveriesStepsComplete;
      }
      this.localStorage.setItem(`show_dlv_stats_${this.location.id}`, this.showDeliveriesStats);
    }

    this.showWorkplaceStats = this.showWorkplaceStats || this.showDeliveriesStats;
  });

  onCloseTask = task({ drop: true }, async () => {
    this.isHeroClosed = true;

    await this.impressions.postImpression.perform(IMPRESSION_NAMES.DASHBOARD.HERO_CLOSED);
    this.logEvent('homepage_hero_close_clicked', this.subscription ? this.subscription.appName : null);
  });
}
