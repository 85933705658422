import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type FeatureConfigService from 'garaje/services/feature-config';
import type LocalStorageService from 'garaje/services/local-storage';
import type StateService from 'garaje/services/state';
import { OauthProvider } from 'garaje/utils/graphql/graphql-types';
import urlBuilder from 'garaje/utils/url-builder';

import { type ModelFrom } from '../utils/type-utils';

import type AnalyticsRoomsRoute from './route';

const ANALYTICS_PROVIDERS = {
  [OauthProvider.Google]: 'google',
  [OauthProvider.MicrosoftService]: 'microsoft',
  [OauthProvider.MicrosoftUser]: 'microsoft',
};

export default class AnalyticsRoomsController extends Controller {
  @service declare state: StateService;
  @service declare localStorage: LocalStorageService;
  @service declare featureConfig: FeatureConfigService;
  @service declare router: RouterService;

  @tracked loginRequired = false;

  declare model: ModelFrom<AnalyticsRoomsRoute>;

  get canAccessAnalytics(): boolean {
    return this.featureConfig.isEnabled('rooms.analytics');
  }

  @action
  navigateToRoomsPlans(): void {
    void this.router.transitionTo('/billing/rooms/plans');
  }

  @action
  trackLoggedIn(): void {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }

  get iframeUrl(): string {
    const company = this.model.company;
    const locationId = this.state.currentLocation?.id;
    const oauthProvider = company.connections[0]?.provider && ANALYTICS_PROVIDERS[company.connections[0]?.provider];

    return urlBuilder.embeddedRoombaLocationAnalyticsMigratedUrl(
      company.id,
      locationId,
      oauthProvider,
      this.loginRequired,
    );
  }
}
