import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import Changeset from 'ember-changeset';
import EmployeeValidations from 'garaje/validations/manually-added-employee';
import config from 'garaje/config/environment';
import lookupValidator from 'ember-changeset-validations';
import { action, get, set } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { APP } from 'garaje/utils/enums';

export default class EmployeesDirectoryAddEmployeeNewController extends Controller {
  @service featureFlags;
  @service('setupGuideStepper') setupGuideStepperService;
  @service skinnyLocations;
  @service state;
  @service store;

  @controller('employees.directory') employeesController;

  queryParams = ['prefill'];
  @tracked prefill = null;
  @tracked changeset;
  defaultCountry = config.defaultCountry;

  setupChangeset() {
    const model = get(this.model, 'employee');
    const validator = lookupValidator(EmployeeValidations);
    const changeset = new Changeset(model, validator, EmployeeValidations);
    const prefill = this.prefill;
    if (prefill) {
      set(changeset, 'name', this.prefill);
      this.prefill = null;
    }
    this.changeset = changeset;
  }

  @action
  searchEmployees(params, limit) {
    if (limit) {
      params.page = { limit };
    }
    return this.store.query('employee', params);
  }

  @action
  addEmployee(newEmployee) {
    set(this.model, 'employee', newEmployee);
    const employee = this.model.employee;
    return employee.save();
  }

  @task
  *afterAddEmployeeTask(employee) {
    this.employeesController.addEmployee(employee);
    yield this.state.checkRestrictionsTask.perform(true);

    if (this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper')) {
      this.setupGuideStepperService.loadSetupStepsTask.perform(APP.VISITORS);
    }

    this.transitionToRoute('employees.directory.employee', employee.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }

  @action
  addToLocation(employeeId) {
    const changeset = this.changeset;
    changeset.addError('email', {
      value: get(changeset, 'email'),
      validation: 'Email found in other location',
    });
    this.transitionToRoute('employees.directory.add-employee.from-location', { queryParams: { employeeId } }); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }
}
