import { action, get } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { later, scheduleOnce } from '@ember/runloop';
import { service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import type AbilitiesService from 'ember-can/services/abilities';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
import config from 'garaje/config/environment';
import type ChameleonService from 'garaje/services/chameleon';
import type CurrentLocationService from 'garaje/services/current-location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type { FlashMessage } from 'garaje/services/flash-messages';
import type GoggleSyncManagerService from 'garaje/services/google-sync-manager';
import type LocalStorageService from 'garaje/services/local-storage';
import type MetricsService from 'garaje/services/metrics';
import type OnlineService from 'garaje/services/online';
import type SessionService from 'garaje/services/session';
import type SessionStorageService from 'garaje/services/session-storage';
import type StateService from 'garaje/services/state';
import type WindowLocationService from 'garaje/services/window-location';
import { routeEvent } from 'garaje/utils/decorators/route';
import redirectToBoss from 'garaje/utils/redirect-to-boss';
import { buildUrl } from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import LogRocket from 'logrocket';

// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
const { ForbiddenError, NotFoundError, UnauthorizedError } = DS;

export default class ApplicationRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare flashMessages: FlashMessagesService;
  @service declare googleSyncManager: GoggleSyncManagerService;
  @service declare localStorage: LocalStorageService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare state: StateService;
  @service declare windowLocation: WindowLocationService;
  @service declare chameleon: ChameleonService;
  @service declare currentLocation: CurrentLocationService;
  @service declare online: OnlineService;
  @service declare sessionStorage: SessionStorageService;

  // NOTE useful for testing
  window = window;

  async beforeModel(transition: Transition): Promise<void> {
    await this.session.setup();

    // Override current location of user via `location` query param to allow
    // legacy invite forms to redirect to invite form with the correct current location
    const overrideCurrentLocationId = transition.to.queryParams['location'];
    if (overrideCurrentLocationId) {
      this.localStorage.setItem('current_location', overrideCurrentLocationId);
      // @ts-ignore
      transition.to.queryParams['location'] = null;
    }
    const defaultRoute = transition.to.queryParams['dr'];
    if (isPresent(defaultRoute)) {
      this.localStorage.setItem('default_route', defaultRoute);
      // @ts-ignore
      transition.to.queryParams['dr'] = null;
    }

    try {
      // @ts-ignore - not sure where "intent" comes from. Not documented anywhere.
      // eslint-disable-next-line ember/no-get
      const intentUrl = new URL(get(transition, 'intent.url'));
      if (!['/', '/login'].includes(intentUrl.pathname)) {
        this.localStorage.setItem('intent_url', intentUrl);
      } else {
        this.localStorage.setItem('intent_url', '');
      }
    } catch (_e) {
      this.localStorage.setItem('intent_url', '');
    }
  }

  activate(): void {
    if (config.isProduction && config.logRocketAppId) {
      LogRocket.init(config.logRocketAppId, {
        dom: {
          inputSanitizer: true,
        },
        network: {
          responseSanitizer: (response) => {
            delete response.body;
            return response;
          },
          requestSanitizer: (request) => {
            delete request.body;
            return request;
          },
        },
        mergeIframes: true,
        childDomains: [
          'https://dashboard.envoy.com/',
          'https://workplace-analytics.envoy.com/',
          'https://customer-dash.envoy.com/',
          'https://app-store.envoy.com/',
          'https://dev-dashboard.envoy.com/',
        ],
      });

      void this.online.runWhenOnline(() => {
        LogRocket.getSessionURL(function (sessionURL) {
          window.analytics.track('LogRocket', {
            sessionURL: sessionURL,
          });
        });
      });
    }

    // Had to wait some time until child routes are done rendering,
    // afterRender state was loading which may still result in error
    later(() => this.handleRetryTracking(), zft(5000));
  }

  handleRetryTracking(): void {
    const recovery_retry_attempt = this.sessionStorage.getItem('recovery_retry_attempt');
    const route = this.router.currentRouteName;
    if (!isEmpty(recovery_retry_attempt) && route.match(/error/) === null) {
      let retry_count: string | number | null = this.sessionStorage.getItem('error_state_retry_count') || null;
      if (retry_count !== null) {
        retry_count = parseInt(retry_count, 10);
      }
      let duration: string | number | null = this.sessionStorage.getItem('error_state_entered_at') || null;
      if (duration !== null) {
        duration = Date.now() - parseInt(duration, 10);
      }
      const props = { recovery_retry_attempt, retry_count, duration };
      this.metrics.trackEvent('Error State Recovered', props);
      ['recovery_retry_attempt', 'error_state_entered_at', 'error_state_retry_count'].map((key) => {
        this.sessionStorage.removeItem(key);
      });
    }
  }

  @routeEvent
  routeDidChange(transition: Transition): void {
    this.chameleon.identifyChameleonUser();

    this.handleWindowScroll(transition);
    this.trackPage();
  }

  @action
  switchLocation(): void {
    void this.refresh();
  }

  @action
  logout(): void {
    void this.session.invalidate();
  }

  @action
  redirectToEnvoy(path: string): void {
    this.windowLocation.assign(buildUrl(path, config.envoyHost));
  }

  @action
  redirectToBoss(path: string): void {
    redirectToBoss(path);
  }

  @action
  error(error: Error): boolean | void {
    if (error instanceof UnauthorizedError) {
      // As a result of a 401 ember-simple-auth calls `this.session.invalidate()` `this.sessionInvalidated()`
      // which will replace the url with root `/` to reboot so can ignore transitioning to an error state.
      this.metrics.trackEvent('Unauthorized Error');

      return false;
    } else if (error instanceof ForbiddenError) {
      void this.intermediateTransitionTo('/forbidden', error);
    } else if (error instanceof NotFoundError) {
      void this.intermediateTransitionTo('/not-found', error);
    } else {
      // Allows error sub-state to handle
      return true;
    }
  }

  @action
  showFlash(...args: Parameters<FlashMessagesService['showFlash']>): void {
    this.flashMessages.showFlash(...args);
  }

  @action
  hideFlash(flash: FlashMessage): void {
    if (flash) {
      this.flashMessages.hideFlash(flash);
    } else {
      this.flashMessages.hideAll();
    }
  }

  @action
  clickLogo(isBoss: boolean): void {
    if (isBoss) {
      this.session._sessionInvalidationFromDeboss = true;
      void this.session.invalidate();
    } else if (isPresent(this.state.vrSubscription)) {
      if (this.abilities.can('visit dashboard')) {
        void this.router.transitionTo('application');
      }
    }
  }

  @action
  sync(): void {
    void this.googleSyncManager.sync();
  }

  @action
  editProfile(): void {
    void this.router.transitionTo('profile');
  }

  @action
  sendFeedback(): void {
    window.location.replace(
      `mailto:feedback@envoy.com?Subject=Feedback%20from%20${this.currentLocation.location.nameWithCompanyName}%20for%20Envoy&Body=Hi%20Envoy!%20I%20have%20a%20suggestion%20for%20you: `,
    );
  }

  handleWindowScroll(transition: Transition): void {
    const transitionFrom = transition.from?.name;
    const transitionTo = transition.to.name;

    if (transitionTo !== transitionFrom) {
      this.window.scrollTo(0, 0);
    }
  }

  trackPage(): void {
    if (this.localStorage.getItem('is_boss') === 'true') return;

    const currentRouteName = () => {
      this.metrics.trackPage(this.router.currentRouteName ?? 'unknown');
      this.chameleon.trackDashboardPageEvent(this.router.currentRouteName ?? 'unknown');
    };

    scheduleOnce('afterRender', this, currentRouteName);
  }
}
