import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AnalyticsRoute extends Route {
  @service abilities;
  @service router;
  @service featureFlags;
  @service featureConfig;

  title = 'Analytics · Envoy';

  beforeModel() {
    if (this.abilities.can('visit occupancy-analytics')) {
      this.router.transitionTo('analytics.occupancy');
    } else if (this.abilities.can('visit-attendance analytic')) {
      this.router.transitionTo('analytics.attendance.overview');
    } else if (this.abilities.can('visit-visitors analytic')) {
      this.router.transitionTo('analytics.visitors');
    } else if (this.abilities.can('visit protect-analytics')) {
      this.router.transitionTo('analytics.protect');
    } else if (this.abilities.can('visit-deliveries analytic') && this.abilities.can('view-analytics delivery-area')) {
      this.router.transitionTo('analytics.deliveries');
    } else if (this.abilities.can('visit-desks analytic') && this.featureConfig.isEnabled('desks.analytics')) {
      this.router.transitionTo('analytics.desks');
    } else if (this.abilities.can('visit-rooms analytic') && this.abilities.can('visit roomba')) {
      this.router.transitionTo('analytics.rooms');
    } else if (this.abilities.can('visit occupancy-location-report')) {
      this.router.transitionTo('analytics.reports');
    } else {
      this.router.transitionTo('unauthorized');
    }
  }
}
