import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from 'ember-data';
import { OauthProvider } from 'garaje/graphql/generated/roomba-types';
import type {
  GetCompanyQuery,
  GetRoomsLocationWithConfigsQuery,
  GetRoomsQuery,
  GetSpaceSaverConfigQuery,
} from 'garaje/graphql/generated/roomba-types';
import type PluginModel from 'garaje/models/plugin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';
import type StateService from 'garaje/services/state';
import { SLACK_V2_PLUGIN_KEY, MSTEAMS_V2_PLUGIN_KEY } from 'garaje/utils/enums';
import urlBuilder from 'garaje/utils/url-builder';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';

interface RoombaResizerComponentArgs {
  company: GetCompanyQuery['company'];
  location: GetRoomsLocationWithConfigsQuery['location'];
  plugins: DS.RecordArray<PluginModel>;
  spaceSaverConfig: GetSpaceSaverConfigQuery['spaceSaverConfig'];
  rooms: GetRoomsQuery['rooms'];
}

const ACCEPTABLE_CHAT_APP_PLUGINS = [SLACK_V2_PLUGIN_KEY, MSTEAMS_V2_PLUGIN_KEY];

export default class RoombaSettingsRoomResizerComponent extends Component<RoombaResizerComponentArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare roombaMetrics: RoombaMetricsService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare featureConfig: FeatureConfigService;

  @tracked isDialogOpen = false;
  @tracked isSettingsAndExceptionsDialogOpen = false;
  @tracked isRoomResizerDisabled: boolean;
  @tracked disabledMessage?: string;
  @tracked disabledMessageLink?: string;

  constructor(owner: unknown, args: RoombaResizerComponentArgs) {
    // eslint-disable-next-line prefer-rest-params
    super(owner, args);
    const { isRoomResizerDisabled, disabledMessage, disabledMessageLink } = this.initializeDisabledState();
    this.disabledMessage = disabledMessage;
    this.isRoomResizerDisabled = isRoomResizerDisabled;
    this.disabledMessageLink = disabledMessageLink;
  }

  @action
  openDialog(): void {
    const { location } = this.args;
    if (!this.isRoomResizerDisabled || location.roomResizerEnabled) {
      this.isDialogOpen = true;
    }
  }

  @action
  closeDialog(): void {
    this.isDialogOpen = false;
  }

  @action
  openSettingsAndExceptionsDialog(): void {
    if (!this.args.location.roomResizerEnabled) {
      return;
    }
    this.isSettingsAndExceptionsDialogOpen = true;
  }

  @action
  closeSettingsAndExceptionsDialog(): void {
    this.isSettingsAndExceptionsDialogOpen = false;
  }

  get shouldShowSettingsAndExceptionsLink(): boolean {
    return this.args.location.roomResizerEnabled;
  }

  initializeDisabledState(): {
    isRoomResizerDisabled: boolean;
    disabledMessage?: string;
    disabledMessageLink?: string;
  } {
    const { company, location, plugins } = this.args;

    if (!this.featureConfig.isEnabled('rooms')) {
      throw new Error('No valid rooms subscription in state');
    }
    const isSubscriptionCompatible = !this.featureConfig.isEnabled('roomMeetings.resizer');
    if (!location.roomResizerEnabled && !isSubscriptionCompatible) {
      return {
        disabledMessage: 'Upgrade to the Standard Plan to access this feature.',
        isRoomResizerDisabled: true,
        disabledMessageLink: urlBuilder.billingDashboardUrl(),
      };
    }

    if (!this.state.currentCompany) {
      throw new Error('No valid current company in state');
    }

    const isChatAppInstalled = plugins.any((plugin: PluginModel) => ACCEPTABLE_CHAT_APP_PLUGINS.includes(plugin.key));

    if (!location.roomResizerEnabled && !isChatAppInstalled) {
      return {
        isRoomResizerDisabled: true,
        disabledMessage: 'Install the Slack or Microsoft Teams app to enable this feature.',
        disabledMessageLink: urlBuilder.appStoreUrl(),
      };
    }

    const isOAuthConnectionCompatible =
      company.connections.filter((connection) => connection.provider === OauthProvider.MicrosoftUser).length === 0;

    if (!location.roomResizerEnabled && !isOAuthConnectionCompatible) {
      return {
        disabledMessage:
          'Room resizer requires Microsoft oAuth with Tenant permissions to enable room swaps. We have detected that you have authenticated your room calendars with user-level permissions. To enable this feature, please disconnect and re-connect your calendar as described in help center article below.',
        isRoomResizerDisabled: true,
        disabledMessageLink: 'https://envoy.help/en/articles/6665861-preparing-your-office-365-calendar',
      };
    }

    return { disabledMessage: undefined, disabledMessageLink: undefined, isRoomResizerDisabled: false };
  }

  /**
   * @task `toggleRoomResizerLocationEnabledTask`
   */
  toggleRoomResizerLocationEnabledTask = task({ drop: true }, async () => {
    const { company, location } = this.args;
    const roomResizerEnabledSetting = !location.roomResizerEnabled;
    this.roombaMetrics.trackEvent(`ROOMS:room_resizer_${roomResizerEnabledSetting ? 'enabled' : 'disabled'}`, {
      location_id: location.id,
      company_id: company.id,
    });
    try {
      await this.roombaGraphql.updateRoomsLocationRoomResizerEnabledOverride(location.id, roomResizerEnabledSetting);
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Error saving location settings');
    } finally {
      this.closeDialog();
    }
    this.flashMessages.showAndHideFlash('success', 'Saved!');
  });

  /**
   * @task `toggleRoomResizerCompanyEnabledTask`
   */
  toggleRoomResizerCompanyEnabledTask = task({ drop: true }, async () => {
    const { company, location } = this.args;
    const roomResizerEnabledSetting = !location.roomResizerEnabled;
    this.roombaMetrics.trackEvent(`ROOMS:room_resizer_company_${roomResizerEnabledSetting ? 'enabled' : 'disabled'}`, {
      location_id: location.id,
      company_id: company.id,
    });
    try {
      await this.roombaGraphql.updateRoomsCompanyRoomResizerEnabled(company.id, roomResizerEnabledSetting);
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Error saving location settings');
    } finally {
      this.closeDialog();
    }
    this.flashMessages.showAndHideFlash('success', 'Saved!');
  });

  get alreadyExcludedRooms(): GetRoomsQuery['rooms'] {
    if (!this.args.rooms) {
      return [];
    }
    const excludedRooms = compact(
      this.args.spaceSaverConfig?.roomResizerExcludedRooms.map((room) => room.room?.id) ?? [],
    );
    return this.args.rooms.filter((room) => {
      return excludedRooms.includes(room.id);
    });
  }

  saveRoomsTask = task({ restartable: true }, async (selectedRooms: GetRoomsQuery['rooms']) => {
    this.roombaMetrics.trackEvent('space_saver_excluded_rooms_updated', {
      selectedRooms: selectedRooms.map((room) => room.name),
    });
    const selectedRoomIds = selectedRooms.map((room) => room.id);
    await this.roombaGraphql.putSpaceSaverRoomResizerExcludedRooms(this.args.company.id, selectedRoomIds);
  });

  get excludedOrganizerEmails(): string[] {
    return this.args.spaceSaverConfig?.roomResizerExcludedOrganizers ?? [];
  }

  saveEmailsTask = task({ restartable: true }, async (emails: string[]) => {
    this.roombaMetrics.trackEvent('space_saver_excluded_emails_updated', { numEmails: emails.length });
    const uniqEmails = uniq(emails);
    await this.roombaGraphql.putSpaceSaverRoomResizerExcludedOrganizers(this.args.company.id, uniqEmails);
  });
}
