import Component from '@glimmer/component';
import { isBlank, isPresent } from '@ember/utils';
import { action } from '@ember/object';
import _intersection from 'lodash/intersection';
import { debounce } from '@ember/runloop';
import { get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';

/**
 * Multiple select on top of `BasicDropdown` for locations and groups.
 * @param {String}                            placeholder           An optional placeholder when no locations are selected
 * @param {String}                            selectedLocationIds   Current selected option ids `1,2,3`.
 * @param {Array<Location|SkinnyLocation>}    locations             List of Location models
 * @param {Array<Group>}                      groups                List of available groups
 * @param {Function}                          onSelected            Function triggered when an option is selected
 * @param {Boolean}                           isDisabled            An optional flag to disable the component
 */

export default class MultiLocationSelect extends Component {
  @tracked searchQuery = '';

  get selectedText() {
    const totalSelected = this.selectedLocations.length;
    if (isBlank(this.selectedLocations) && !isBlank(this.args.placeholder)) {
      return htmlSafe(`<span class="text-carbon-40">${this.args.placeholder}</span>`);
    } else if (isBlank(this.selectedLocations) || totalSelected === this.args.locations.length) {
      return 'All locations';
    } else if (this.singleGroupSelected) {
      return get(this.selectedGroups[0], 'name');
    } else if (totalSelected === 1) {
      return get(this.selectedLocations[0], 'name');
    } else {
      return `${totalSelected} locations`;
    }
  }

  get selectedLocations() {
    const ids = (this.args.selectedLocationIds || '').split(',');
    return this.args.locations.filter((location) => ids.includes(get(location, 'id')));
  }

  get selectedGroups() {
    return this.args.groups.filter((group) => {
      if (
        group.locations.length !== 0 &&
        group.locations.every((l) => this.selectedLocations.mapBy('id').includes(get(l, 'id')))
      ) {
        return group;
      }
    });
  }

  get singleGroupSelected() {
    return (
      this.selectedGroups.length === 1 && this.selectedGroups[0].locations.length === this.selectedLocations.length
    );
  }

  get options() {
    const options = [];
    // All the locations that do not belong to any group
    const locationsWithoutGroup = this.args.locations.filter(
      (location) => !get(location, 'group') || isBlank(this.args.groups),
    );

    locationsWithoutGroup.forEach((location) =>
      options.push({ isParent: false, name: get(location, 'name'), data: location }),
    );

    if (isPresent(this.args.groups)) {
      // All the available groups with their states
      this.args.groups.forEach((group) => {
        if (group.totalLocations > 0) {
          options.push({ isParent: true, name: group.name, data: group });
        }
      });
    }

    options.sort((op1, op2) => op1.name - op2.name);

    return options;
  }

  /**
   * @return {Array<Object>}
   */
  get filteredOptions() {
    if (isBlank(this.searchQuery)) {
      return this.options;
    }

    const filteredOptions = this.options.reduce((filteredOpts, option) => {
      // If the top level option matches the search query, render the option
      // along with all its nested locations.
      if (this._matchesSearchQuery(option.name)) {
        filteredOpts.push(option);
        return filteredOpts;
      }

      // If the option doesn't directly match the search and it's a group,
      // check its nested locations for a match.
      if (option.isParent) {
        const filteredLocations = option.data.locations.filter((loc) => this._matchesSearchQuery(get(loc, 'name')));

        if (filteredLocations.length) {
          filteredOpts.push({
            ...option,
            data: {
              ...option.data,
              locations: filteredLocations,
            },
          });
        }
      }

      return filteredOpts;
    }, []);

    return filteredOptions;
  }

  get allSelected() {
    return this.args.locations?.length === this.selectedLocations?.length;
  }

  get noneSelected() {
    return this.selectedLocations?.length === 0;
  }

  setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action
  handleSearch(value) {
    debounce(this, this.setSearchQuery, value, 250);
  }

  /**
   * Handles the `group` selected action. If the `group` is in an `indeterminate` state, we want to clear the group.
   * Otherwise, we select every locations in the group.
   * @param {Object}  optionGroup
   */
  @action
  onGroupSelected(optionGroup) {
    let locationIds;

    const groupLocations = optionGroup.data.locations.toArray(); // every locations that belong to a group selected
    const groupLocationIds = optionGroup.data.locations.mapBy('id');
    const preSelectedLocationIds = this.selectedLocations.mapBy('id');

    // check if any location of the selected group is already selected,
    // if so, we want to clear the group by sending locations that are aleady selected
    // otherwise, we want to select every locataions in the group
    if (groupLocationIds.some((l) => preSelectedLocationIds.includes(l))) {
      locationIds = groupLocationIds.filter((l) => preSelectedLocationIds.includes(l));
    } else {
      locationIds = groupLocationIds;
    }

    const locations = groupLocations.filter((l) => locationIds.includes(get(l, 'id')));
    // only new locations to be selected/unselected gets passed here.
    this.args.onSelected(locations);
  }

  /**
   * Helper that matches a string against this.searchQuery. Returns true if
   * there's a string overlap, false if not.
   *
   * @param {String}    str
   * @return {Boolean}
   */
  _matchesSearchQuery(str) {
    return str.toLowerCase().includes(this.searchQuery.toLowerCase());
  }

  @action
  onDeselectAll() {
    this.args.onSelected(this.selectedLocations);
  }

  @action
  onSelectAll() {
    const unselected = this.args.locations.filter((l) => !this.selectedLocations.mapBy('id').includes(get(l, 'id')));
    this.args.onSelected(unselected);
  }
}
