import { action, get } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type AjaxServiceClass } from 'ember-ajax/services/ajax';
import { task } from 'ember-concurrency';
import type UserModel from 'garaje/models/user';
import type UserDocument from 'garaje/models/user-document';
import type UserDocumentLinkModel from 'garaje/models/user-document-link';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import urlBuilder from 'garaje/utils/url-builder';

const APPROVED_STATUS = 'approved';
const DENIED_STATUS = 'denied';
const REVIEW_STATUS = 'review';

export default class EmployeesDocumentReviewerComponent extends Component<{ userDocumentLink: UserDocumentLinkModel }> {
  @service declare flashMessages: FlashMessagesService;
  @service declare ajax: AjaxServiceClass;
  @service declare router: RouterService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  get user(): UserModel | undefined {
    // eslint-disable-next-line ember/no-get, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
    return get(this, 'userDocument.user') as any;
  }

  get userDocument(): UserDocument | undefined {
    // using a dangerous cast to preserve historical behavior
    return this.args.userDocumentLink?.userDocument as unknown as UserDocument | undefined;
  }

  get approveButtonDisabled(): boolean {
    return this.isDenied || this.saveDocumentTask.isRunning;
  }

  get denyButtonDisabled(): boolean {
    return this.isApproved || this.saveDocumentTask.isRunning;
  }

  get canReset(): boolean {
    return this.isApproved || this.isDenied;
  }

  get isApproved(): boolean {
    return this.args.userDocumentLink.approvalStatus === APPROVED_STATUS;
  }

  get isDenied(): boolean {
    return this.args.userDocumentLink.approvalStatus === DENIED_STATUS;
  }

  get hasInputs(): boolean {
    // eslint-disable-next-line ember/no-get
    const fields = get(this.userDocument, 'inputFieldsData');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    return Object.keys(fields as any).length > 0;
  }

  get isGlobalOverview(): boolean {
    return this.router.currentRouteName?.includes('location-overview');
  }

  /**
   * Using a lot of disables to preserve behavior
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get attachments(): any {
    // eslint-disable-next-line ember/no-get
    const userDocumentTemplate = get(this.userDocument, 'userDocumentTemplate');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, ember/no-get, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    const templateAttachments = ((get(userDocumentTemplate, 'userDocumentTemplateAttachments') || []) as any).sortBy(
      'position',
    );
    // eslint-disable-next-line ember/no-get
    const userDocumentAttachments = get(this.userDocument, 'userDocumentAttachments');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return templateAttachments.map((templateAttachment: { id: unknown }) => {
      return {
        template: templateAttachment,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        user: (userDocumentAttachments as any).find(
          (userDocumentAttachment: unknown) =>
            // eslint-disable-next-line ember/no-get
            get(userDocumentAttachment, 'userDocumentTemplateAttachment.id') === templateAttachment.id,
        ),
      };
    });
  }

  @action
  async approveDocument(): Promise<void> {
    const url = urlBuilder.v3.userDocuments.approveUserDocumentLink(this.args.userDocumentLink.id);
    await this.saveDocumentTask.perform(APPROVED_STATUS, url);
  }

  @action
  async denyDocument(): Promise<void> {
    const url = urlBuilder.v3.userDocuments.denyUserDocumentLink(this.args.userDocumentLink.id);
    await this.saveDocumentTask.perform(DENIED_STATUS, url);
  }

  @action
  async resetStatus(): Promise<void> {
    const url = urlBuilder.v3.userDocuments.resetUserDocumentLink(this.args.userDocumentLink.id);
    await this.saveDocumentTask.perform(REVIEW_STATUS, url);
  }

  saveDocumentTask = task({ drop: true }, async (status: string, url: string) => {
    const userDocumentLink = this.args.userDocumentLink;

    this.workplaceMetrics.trackEvent('REVIEW_EMPLOYEE_DOCUMENTS_BTN_CLICKED', {
      status,
      id: userDocumentLink.id,
    });

    // don't update if status isn't changing
    if (status === userDocumentLink.approvalStatus) return;

    try {
      await this.ajax.request(url, {
        type: 'POST',
        contentType: 'application/vnd.api+json',
      });
      this.workplaceMetrics.trackEvent('REVIEW_EMPLOYEE_DOCUMENTS_SAVE_SUCCESSFUL', {
        status,
        id: userDocumentLink.id,
      });
      await userDocumentLink.reload();
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'REVIEW_EMPLOYEE_DOCUMENTS_SAVE_FAILED',
        debugExtras: {
          status,
          id: userDocumentLink.id,
        },
        error: e,
      });
      let verb = 'reviewing';
      if (status === APPROVED_STATUS) verb = 'approving';
      if (status === DENIED_STATUS) verb = 'denying';
      if (status === REVIEW_STATUS) verb = 'resetting';
      this.flashMessages.showAndHideFlash('error', `Error ${verb} document`);
    }
  });
}
