import Service, { inject as service } from '@ember/service';
import type {
  Audience,
  GQLAudiences,
  GQLDeliveryMethod,
  GQLTemplate,
  Location,
  MessageInput,
  SentMessage,
  TemplateCategory,
} from '@envoy/components-communication';
import { Audiences } from '@envoy/components-communication';
import {
  type GetSkinnyLocationsQuery,
  type GetSkinnyLocationsVariables,
  type CreateAnnouncementMutation,
  type CreateAnnouncementVariables,
  type GetAnnouncementTemplateNamesForLocationByCategoryQuery,
  type PopulateAnnouncementTemplateQuery,
  type PopulateAnnouncementTemplateVariables,
  type GetAnnouncementAudienceSizesQuery,
  type GetAnnouncementAudienceSizesVariables,
  type GetAnnouncementTemplateNamesForLocationByCategoryVariables,
} from 'garaje/graphql/generated/announcement-types';
import createAnnouncementMutation from 'garaje/graphql/mutations/CreateAnnouncementMutation';
import announcementAudienceSizesQuery from 'garaje/graphql/queries/AnnouncementAudienceSizesQuery';
import announcementTemplateNamesForLocationByCategoryQuery from 'garaje/graphql/queries/AnnouncementTemplateNamesForLocationByCategory';
import populateAnnouncementTemplateQuery from 'garaje/graphql/queries/PopulateAnnouncementTemplateQuery';
import skinnyLocationsQuery from 'garaje/graphql/queries/SkinnyLocationsQuery';
import type ApolloService from 'garaje/services/apollo-extension';
import type MetricsService from 'garaje/services/metrics';

enum EmployeeAudienceType {
  Self = 'self',
  AllEmployeesWithReservation = 'allEmployeesWithReservation',
  AllEmployeesCheckedIn = 'allEmployeesCheckedIn',
  AllEmployeesAtLocation = 'allEmployeesAtLocation',
  AllEmployeesAtDefaultLocation = 'allEmployeesAtDefaultLocation',
}

enum VisitorAudienceType {
  AllVisitorsWithReservation = 'allVisitorsWithReservation',
  AllVisitorsCheckedIn = 'allVisitorsCheckedIn',
  AllVisitorsSignedOut = 'allVisitorsSignedOut',
}

const audienceTypeMap: Record<EmployeeAudienceType | VisitorAudienceType, Audiences> = {
  [EmployeeAudienceType.Self]: Audiences.Self,
  [EmployeeAudienceType.AllEmployeesWithReservation]: Audiences.AllEmployeesWithReservation,
  [EmployeeAudienceType.AllEmployeesCheckedIn]: Audiences.AllEmployeesCheckedIn,
  [EmployeeAudienceType.AllEmployeesAtLocation]: Audiences.AllEmployeesAtLocation,
  [EmployeeAudienceType.AllEmployeesAtDefaultLocation]: Audiences.AllEmployeesAtDefaultLocation,
  [VisitorAudienceType.AllVisitorsWithReservation]: Audiences.AllVisitorsWithReservation,
  [VisitorAudienceType.AllVisitorsCheckedIn]: Audiences.AllVisitorsCheckedIn,
  [VisitorAudienceType.AllVisitorsSignedOut]: Audiences.AllVisitorsSignedOut,
};

export default class CommunicationDataService extends Service {
  @service declare apolloExtension: ApolloService;
  @service declare metrics: MetricsService;

  async getTemplateCategories(locationId: string): Promise<TemplateCategory[]> {
    try {
      const result = await this.apolloExtension.query<
        GetAnnouncementTemplateNamesForLocationByCategoryQuery,
        GetAnnouncementTemplateNamesForLocationByCategoryVariables,
        'announcementTemplateNamesForLocationByCategory'
      >(
        {
          query: announcementTemplateNamesForLocationByCategoryQuery,
          variables: {
            locationId,
          },
          fetchPolicy: 'network-only',
        },
        'announcementTemplateNamesForLocationByCategory',
      );

      return result;
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getTemplateCategories query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async getTemplate(locationId: string, templateId: string): Promise<GQLTemplate> {
    try {
      const populateAnnouncementTemplate = await this.apolloExtension.query<
        PopulateAnnouncementTemplateQuery,
        PopulateAnnouncementTemplateVariables,
        'populateAnnouncementTemplate'
      >(
        {
          query: populateAnnouncementTemplateQuery,
          variables: {
            announcementTemplateId: templateId,
            locationId,
          },
        },
        'populateAnnouncementTemplate',
      );

      this.metrics.trackEvent('COMMUNICATIONS_POPULATED_ANNOUNCEMENT_TEMPLATE', {
        template: populateAnnouncementTemplate,
      });

      return {
        title: populateAnnouncementTemplate.title,
        message: populateAnnouncementTemplate.message,
        deliveryMethods: populateAnnouncementTemplate.defaultChannels as GQLDeliveryMethod[],
        employeeGroup: (populateAnnouncementTemplate.defaultEmployeeAudiences[0] as Audiences) || '',
        visitorGroup: (populateAnnouncementTemplate.defaultVisitorAudiences[0] as Audiences) || '',
        markAsSafe: populateAnnouncementTemplate.markAsSafe,
      };
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getTemplate query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async getAudiences(locationId: string): Promise<GQLAudiences> {
    try {
      const result = await this.apolloExtension.query<
        GetAnnouncementAudienceSizesQuery,
        GetAnnouncementAudienceSizesVariables,
        'announcementAudienceSizes'
      >(
        {
          query: announcementAudienceSizesQuery,
          variables: {
            locationId,
          },
        },
        'announcementAudienceSizes',
      );

      const { employeeAudienceCount, visitorAudienceCount } = result[0]!;

      // this determines the order of values in dropdown too
      return {
        employees: [
          this.getEmployeeAudienceValue(EmployeeAudienceType.Self, employeeAudienceCount),
          this.getEmployeeAudienceValue(EmployeeAudienceType.AllEmployeesCheckedIn, employeeAudienceCount),
          this.getEmployeeAudienceValue(EmployeeAudienceType.AllEmployeesWithReservation, employeeAudienceCount),
          this.getEmployeeAudienceValue(EmployeeAudienceType.AllEmployeesAtDefaultLocation, employeeAudienceCount),
          this.getEmployeeAudienceValue(EmployeeAudienceType.AllEmployeesAtLocation, employeeAudienceCount),
        ],
        visitors: [
          this.getVisitorAudienceValue(VisitorAudienceType.AllVisitorsCheckedIn, visitorAudienceCount),
          this.getVisitorAudienceValue(VisitorAudienceType.AllVisitorsWithReservation, visitorAudienceCount),
          this.getVisitorAudienceValue(VisitorAudienceType.AllVisitorsSignedOut, visitorAudienceCount),
        ],
      };
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getAudiences query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async sendMessage(input: MessageInput): Promise<SentMessage> {
    // To support both string and array values for employeegroups
    // Can be removed when we want to delete the split flag making it single select
    const employeeGroup = Array.isArray(input.employeeGroup)
      ? input.employeeGroup
      : input.employeeGroup
        ? [input.employeeGroup]
        : [];

    const visitorGroup = Array.isArray(input.visitorGroup)
      ? input.visitorGroup
      : input.visitorGroup
        ? [input.visitorGroup]
        : [];

    const announcementInput = {
      title: input.title,
      message: input.message,
      channels: input.deliveryMethods,
      audiences: [
        ...employeeGroup.map((type) => ({ locationId: input.locationId, type })),
        ...visitorGroup.map((type) => ({ locationId: input.locationId, type })),
      ].filter((audience) => !!audience),
      actions: input.markAsSafe ? ['MARK_AS_SAFE'] : [],
      type: input.critical ? 'EMERGENCY' : 'ANNOUNCEMENT',
    };

    const announcement = await this.apolloExtension.mutate<
      CreateAnnouncementMutation,
      CreateAnnouncementVariables,
      'createAnnouncement'
    >(
      {
        mutation: createAnnouncementMutation,
        variables: {
          announcementInput,
        },
      },
      'createAnnouncement',
    );

    this.metrics.trackEvent('COMMUNICATIONS_SEND_ANNOUNCEMENT', {
      announcement: announcementInput,
    });

    const {
      statistics: {
        sentCount: { employeesCount, visitorsCount },
      },
    } = announcement;

    return {
      id: announcement.id,
      sentCount: employeesCount + visitorsCount,
    };
  }

  async getLocations(): Promise<Location[]> {
    try {
      const result = await this.apolloExtension.query<
        GetSkinnyLocationsQuery,
        GetSkinnyLocationsVariables,
        'locations'
      >(
        {
          query: skinnyLocationsQuery,
          variables: {},
        },
        'locations',
      );

      return result;
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getTemplateCategories query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  private getEmployeeAudienceValue(type: EmployeeAudienceType, counts: Record<EmployeeAudienceType, number>): Audience {
    return {
      id: audienceTypeMap[type],
      count: counts[type],
    };
  }

  private getVisitorAudienceValue(type: VisitorAudienceType, counts: Record<VisitorAudienceType, number>): Audience {
    return {
      id: audienceTypeMap[type],
      count: counts[type],
    };
  }
}
