import type RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import type ZoneModel from 'garaje/models/zone';
import type LocalStorageService from 'garaje/services/local-storage';

export const CURRENT_LOCATION_KEY = 'current_location';
export const CURRENT_ZONE_KEY = 'current_zone';

/**
 * Service to help facilitate transitions between locations and properties
 */
export default class ContextSwitcherService extends Service {
  @service declare localStorage: LocalStorageService;
  @service declare router: RouterService;

  /**
   * Transitions to a property route when needing to apply a new property context
   *
   * @param routeName - name of route
   * @param property - zone model
   * @param queryParams - any query params to be passed into route
   */
  async transitionToPropertyRoute(
    routeName: string,
    property: ZoneModel,
    queryParams: Record<string, unknown> = {},
  ): Promise<void> {
    this.zoneId = property.id;
    try {
      void this.router.refresh('application');
      await this.router.transitionTo(routeName, { queryParams });
    } catch (e) {
      if ((<{ message: string }>e).message !== 'TransitionAborted') {
        throw e;
      }
    }
  }

  /**
   * Cached location id used for location switching.
   * Setting this value will clear the cached zone id and update the current location id in local storage.
   */
  get locationId(): string | null {
    return this.#getCachedId(CURRENT_LOCATION_KEY);
  }
  set locationId(locationId: string | null) {
    this.localStorage.setItem(CURRENT_LOCATION_KEY, locationId);
    this.localStorage.removeItem(CURRENT_ZONE_KEY);
  }

  /**
   * cached zone id used for property switching
   * Setting this value will update the current zone id in local storage.
   */
  get zoneId(): string | null {
    return this.#getCachedId(CURRENT_ZONE_KEY);
  }
  set zoneId(zoneId: string | null) {
    this.localStorage.setItem(CURRENT_ZONE_KEY, zoneId);
  }

  #getCachedId(key: string): string | null {
    const id = this.localStorage.getItem(key);
    return id === 'null' ? null : id;
  }
}
