import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, set, setProperties } from '@ember/object';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { dropTask } from 'ember-concurrency';
import { reads, equal } from 'macro-decorators';
import { WORKPLACE_SEAT_MINIMUM } from 'garaje/utils/plan-details';

const PROCUREMENT_SOURCES = [
  'Envoy in a Box',
  'Apple.com',
  'Apple Retail Store',
  'CDW',
  'Compucom',
  'Insight',
  'PCC',
  'PCMall',
  'SHI',
  'Zones',
  'Already had an iPad',
];

export default class BillingSubscribeController extends Controller {
  @service billing;
  @service currentLocation;
  @service flashMessages;
  @service linkManager;
  @service metrics;
  @service state;
  @service store;
  @service router;

  queryParams = ['plan', 'period', 'coupon'];

  @tracked plan;
  @tracked period = 'yearly';
  @tracked coupon;
  @tracked lastValidCouponCode = null;
  @tracked isPaymentMethodComplete = false;
  @tracked createStripeToken = () => {};
  @tracked hostedPageLink = '';
  @tracked openChargeBeeModal = false;
  @tracked needReloadPaymentSources = false;
  // Disable the pay button until the seat requirement for workplace premium plus is met
  @tracked isPaymentDisabledMinSeats = false;

  minWorkplaceSeatNumber = WORKPLACE_SEAT_MINIMUM;
  procurementSources = PROCUREMENT_SOURCES;

  @reads('model.paymentSource.exists') hasExistingPaymentMethod;

  @equal('model.product', 'empxp') isWorkplace;
  @equal('model.product', 'vfd') isVfd;

  // TODO: Double check properties still are returned for LBB subscriptions
  get isCurrentPlanAndPeriodIsMonthly() {
    return this.state.vrSubscription.plan === this.plan && this.state.vrSubscription.period === 'monthly';
  }

  get backLink() {
    if (this.isWorkplace) {
      return this.linkManager.createUILink({
        route: 'billing.product-plans.index',
        models: ['workplace'],
      });
    }
    if (this.isVfd) {
      return this.linkManager.createUILink({ route: 'billing' });
    }

    return this.linkManager.createUILink({
      route: 'billing.product-plans.index',
      models: ['visitors'],
    });
  }

  @dropTask
  *openChargeBeeLink() {
    if (this.hostedPageLink) {
      this.openChargeBeeModal = true;
      return;
    }

    try {
      const { currentCompany } = this.state;
      const hostedPage = this.store.createRecord('hosted-page', {
        companyId: currentCompany.id,
      });
      yield hostedPage.save();
      if (hostedPage.url) {
        set(this, 'hostedPageLink', hostedPage.url);
        this.openChargeBeeModal = true;
      } else {
        this.flashMessages.showFlash('error', 'Something went wrong. Please try again later.');
      }
    } catch (e) {
      this.flashMessages.showFlash('error', 'Something went wrong. Please try again later.');
    }
  }

  @dropTask
  *reloadPaymentSources() {
    if (this.needReloadPaymentSources) {
      const reloadPaymentSources = yield this.billing.loadPaymentSources.perform(true);
      set(this.model, 'paymentSources', reloadPaymentSources);
      set(this.model, 'paymentSource', reloadPaymentSources.firstObject);
      this.send('refreshModel');

      this.needReloadPaymentSources = false;
    }
  }

  @dropTask
  *createPaymentSourceTask(stripeToken) {
    set(this.model.paymentSource, 'stripeTempToken', stripeToken);
    yield this.model.paymentSource.save();
    this.metrics.trackEvent('Account modified', {
      product: 'account',
      account_change_type: 'payment_method',
      account_change_object: 'credit_card',
      account_change_action: 'added',
    });
  }

  @dropTask
  *subscribeTask(response) {
    const stripeToken = response ? response.id : null;
    let subscription = this.model.subscription;
    const quantity = this.model.quantity;
    const subscriptionProperties = {
      couponCode: this.lastValidCouponCode,
      period: this.period,
      plan: this.model.planLevel,
      quantity: quantity,
    };

    const { currentCompany } = this.state;

    try {
      if (!this.hasExistingPaymentMethod) {
        yield this.createPaymentSourceTask.perform(stripeToken);
      }

      if (subscription === undefined) {
        // Create Subscription
        subscription = this.store.createRecord('subscription', {
          ...subscriptionProperties,
          app: this.model.product,
          company: currentCompany,
          trialEndDate: new Date(),
        });
        yield subscription.save();
      } else {
        setProperties(subscription, subscriptionProperties);
        yield subscription.save();

        if (subscription.isOnTrial) {
          yield subscription.purchase({
            period: this.period,
            quantity: quantity,
            coupon: this.lastValidCouponCode,
          });
          set(subscription, 'downgradePlan', null);
        }
      }

      // Reload state service with the new subscription added
      yield this.state.initSubscriptionStateTask.perform();
      this.currentLocation.setupSegment();
      this.flashMessages.showAndHideFlash('success', 'Saved!');

      if (this.isVfd) {
        this.router.transitionTo('billing', {
          queryParams: { vfdPurchased: 'true' },
        });
      } else {
        this.router.transitionTo('billing');
      }
    } catch (e) {
      if (subscription !== undefined) {
        subscription.rollbackAttributes();
      }

      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *subscribeNowTask() {
    if (this.hasExistingPaymentMethod) {
      yield this.subscribeTask.perform();
    } else if (this.isPaymentMethodComplete) {
      try {
        const stripeResponse = yield this.createStripeToken();
        if (stripeResponse.error) {
          throw new Error(stripeResponse.error.message);
        }
        return yield this.subscribeTask.perform(stripeResponse.token);
      } catch (e) {
        this.flashMessages.showFlash('error', 'Stripe error', parseErrorForDisplay(e));
      }
    }
  }

  @action
  handlePaymentMethodChange({ complete, createStripeToken }) {
    this.isPaymentMethodComplete = complete;
    this.createStripeToken = createStripeToken;
  }

  @action
  setLastValidCouponCode(couponCode) {
    this.lastValidCouponCode = couponCode;
  }

  @action
  setNewPeriod(newPeriod) {
    this.period = newPeriod;
  }

  @action
  setQuantity(quantity) {
    const oldQuantity = this.model.quantity;

    // Prevent a null or negative quantity from causing an error in the subscription-estimate call
    if (!quantity || isNaN(quantity) || quantity <= 0) {
      set(this.model, 'quantity', oldQuantity);
      return;
    }

    if (this.isWorkplace) {
      if (quantity < WORKPLACE_SEAT_MINIMUM) {
        this.isPaymentDisabledMinSeats = true;
      } else {
        this.isPaymentDisabledMinSeats = false;
      }
    }
    set(this.model, 'quantity', quantity);
  }

  @action
  closeChargeBeeWalletModal() {
    this.openChargeBeeModal = false;
    this.reloadPaymentSources.perform();
  }
}
