import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import manualPropagableHandler from 'garaje/utils/manual-propagable-handler';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { action } from '@ember/object';
import { enqueueTask, dropTask, restartableTask, timeout, all } from 'ember-concurrency';
import { alias } from 'macro-decorators';

export default class SettingsWelcomeScreenDesignController extends Controller {
  @service flashMessages;
  @service('global-setting-batch') globalSettingBatchService;
  @service state;
  @service store;
  @service visitorsOnboarding;
  @service featureFlags;

  @tracked onlyShowSlideshow;
  expandedWelcomeReferral = false;
  isEditingWelcomeImage = false;

  @alias('globalSettingBatchService.currentGlobalSettingBatch') globalSettingBatch;
  @alias('model.slideshows') slideshows;
  @alias('model.config') config;

  get isUpgradeRequired() {
    return !this.state.vrSubscription?.canAccessSlideshows && !this.state.vfdSubscription?.canAccessSlideshows;
  }

  @action
  addSlideshow(url) {
    const slide = this.store.createRecord('slideshow');
    slide.url = url;

    this.model.slideshows.addObject(slide);
  }

  @enqueueTask({
    maxConcurrency: 3,
  })
  *removeSlideshowsTask(slideshows) {
    try {
      yield all(slideshows.invoke('save'));
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.throttledRefreshConfigTask.perform();
      if (this.visitorsOnboarding.showVideoWalkthrough) {
        this.visitorsOnboarding.loadLocationsTask.perform(true);
      }
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @restartableTask
  *throttledRefreshConfigTask() {
    yield timeout(2000);
    yield this.refreshConfigTask.perform();
  }

  @dropTask
  *toggleOnlyShowSlideshow() {
    try {
      yield this.model.changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.config.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @restartableTask
  *refreshConfigTask() {
    yield this.config.refreshConfig();
  }

  @action
  setWelcomeImage(data) {
    this.store.push(this.store.normalize('config', data.data));
    manualPropagableHandler(this.globalSettingBatch, 'config', 'welcomeImage');
    if (this.visitorsOnboarding.showVideoWalkthrough) {
      this.visitorsOnboarding.loadLocationsTask.perform(true);
    }
  }

  @action
  deleteWelcomeImage() {
    const store = this.store;
    const model = this.config;
    return model.deleteWelcomeImage().then((data) => {
      const normalized = store.normalize('config', data.data);
      // if welcomeImage is not present, then it is not included in the payload. We need to nullify the key.
      normalized.data.attributes.welcomeImage = null;
      store.push(normalized);
      manualPropagableHandler(this.globalSettingBatch, 'config', 'welcomeImage');
      if (this.visitorsOnboarding.showVideoWalkthrough) {
        this.visitorsOnboarding.loadLocationsTask.perform(true);
      }
    });
  }

  @action
  async finalizeSlideshow(data) {
    const store = this.store;
    const slideshows = this.slideshows;

    // find and clean up a single placeholder for the single upload that was completed
    const placeholder = slideshows.findBy('isNew');
    if (placeholder) {
      const index = slideshows.findIndex((slideshow) => slideshow === placeholder);
      if (index !== -1) {
        slideshows.splice(index, 1);
      }
      placeholder.rollbackAttributes();
    }

    // load in the real one
    store.pushPayload('slideshow', data);
    const serializedSlideshow = await store.findRecord('slideshow', data.slideshow.id);

    this.config.slideshows.addObject(serializedSlideshow);
    this.slideshows.addObject(serializedSlideshow);

    if (this.visitorsOnboarding.showVideoWalkthrough) {
      this.visitorsOnboarding.loadLocationsTask.perform(true);
    }
  }

  @action
  setOnlyShowSlideshow(val) {
    this.onlyShowSlideshow = val;
  }
}
