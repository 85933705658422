import Component from '@glimmer/component';
import type AnnouncementModel from 'garaje/models/announcement';
import { ANNOUNCEMENT_CHANNEL_NAME, ANNOUNCEMENT_RECIPIENT_GROUP_NAME } from 'garaje/utils/enums';

type AnnouncementDetailsArgsType = {
  model: AnnouncementModel;
};

export default class AnnouncementDetails extends Component<AnnouncementDetailsArgsType> {
  get deliveryMethods(): string[] {
    return this.args.model.announcementChannels.map((c) => this.formatChannelName(c.channel));
  }

  get recipientGroups(): string[] {
    const announcementAudiences = this.args.model.announcementAudiences;
    const locationName = this.args.model.location.content?.name || '';

    return announcementAudiences.map((a) =>
      this.formatRecipientGroup(
        (a.visitorAudience || a.employeeAudience) as keyof typeof ANNOUNCEMENT_RECIPIENT_GROUP_NAME,
        locationName,
      ),
    );
  }

  formatChannelName(channel: keyof typeof ANNOUNCEMENT_CHANNEL_NAME): string {
    return ANNOUNCEMENT_CHANNEL_NAME[channel];
  }

  formatRecipientGroup(recipientGroup: keyof typeof ANNOUNCEMENT_RECIPIENT_GROUP_NAME, locationName: string): string {
    return ANNOUNCEMENT_RECIPIENT_GROUP_NAME[recipientGroup]?.replace('%{location_name}', locationName);
  }
}
