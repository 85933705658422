/* eslint-disable ember/no-computed-properties-in-native-classes */
import { reads } from '@ember/object/computed';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { task } from 'ember-concurrency';
import type CompanyModel from 'garaje/models/company';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type FlowService from 'garaje/services/flow';
import type IsOpenService from 'garaje/services/is-open';
import type StateService from 'garaje/services/state';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
import { DEFAULT_FLOW_NAME } from 'garaje/utils/enums';

interface SideBarLocationMenuComponentSignature {
  Args: {
    company: CompanyModel;
    trackClickEvent: unknown;
  };
}

export default class SideBarLocationMenuComponent extends Component<SideBarLocationMenuComponentSignature> {
  @service declare isOpen: IsOpenService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;
  @service declare store: StoreService;
  @service('flow') declare employeeScreeningFlow: FlowService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;
  @service declare abilities: AbilitiesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;

  @reads('employeeScreeningFlow.activeEmployeeScreeningFlow')
  activeEmployeeScreeningFlow!: FlowService['activeEmployeeScreeningFlow'];
  @reads('state.currentLocation') currentLocation!: StateService['currentLocation'];
  @reads('state.vrSubscription') vrSubscription!: StateService['vrSubscription'];
  @reads('state.workplaceSubscription') workplaceSubscription!: StateService['workplaceSubscription'];
  @reads('state.deliveriesSubscription') deliveriesSubscription!: StateService['deliveriesSubscription'];
  @reads('state.desksSubscription') desksSubscription!: StateService['desksSubscription'];
  @reads('currentAdmin.isGlobalAdmin') isGlobalAdmin!: CurrentAdminService['isGlobalAdmin'];
  @reads('currentAdmin.isLocationAdmin') isLocationAdmin!: CurrentAdminService['isLocationAdmin'];
  @reads('state.currentCompany') currentCompany!: StateService['currentCompany'];

  @tracked hasFloorWithMap = false;

  employeeRegistrationFilter = DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING;

  get canViewWelcomePage(): boolean {
    if (
      this.state.currentUser.visitorsProductVideosEnabled &&
      this.featureFlags.isEnabled('webinar-signup') &&
      (this.isGlobalAdmin || this.isLocationAdmin)
    ) {
      return true;
    }
    return false;
  }

  get showApprovalsLink(): boolean {
    return this.abilities.can('review invites');
  }

  get shouldHideNavItems(): boolean {
    return !this.state.hasSubscriptions && this.featureFlags.isEnabled('growth_hide_nav_items_before_trial');
  }

  get canViewDeviceAlertsNav(): boolean {
    return this.abilities.can('update printer notifications') || this.abilities.can('update device notifications');
  }

  hasFloorWithMapTask = task({ drop: true }, async () => {
    try {
      const floors = await this.store.query('floor', { filter: { 'location-id': this.state.currentLocation.id } });
      const floorsWithFloorPlan = floors.filter((floor) => {
        return floor.floorPlanUrl;
      });
      this.hasFloorWithMap = isPresent(floorsWithFloorPlan);
    } catch (_error) {
      this.flashMessages.showFlash(
        'error',
        'Some of our backend systems are currently down.  Desks-related features may not work properly.  This should be resolved soon.',
      );
      this.hasFloorWithMap = false;
    }
  });
}
