import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { timeout, dropTask, restartableTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import zft from 'garaje/utils/zero-for-tests';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

/**
 * @param {Boolean}                   deviceNotificationsHasChanges
 * @param {Boolean}                   hasDevices
 * @param {Class<Location>}           location
 * @param {Function}                  onUpdate
 * @param {Function}                  searchUsersTask
 */
export default class DeviceNotifications extends Component {
  @service flashMessages;
  @service impressions;

  @tracked isOpen = false;
  @tracked isShowingRollbackConfirmation = false;

  @dropTask
  *enableTask() {
    try {
      set(this.args.location, 'deviceNotificationsEnabled', true);
      yield this.args.location.save();
      this.isOpen = true;
      this.args.location.pinDeviceContacts();
      this.flashMessages.showAndHideFlash('success', 'iPad notifications enabled!');
      yield this.impressions.postImpression.perform(
        IMPRESSION_NAMES.VR_NOTIFICATIONS_DEVICE_IPAD_STATUS_EMAIL['ENABLED'],
      );
    } catch (e) {
      this.args.location.rollbackAttributes();
      this.isOpen = false;
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *disableTask() {
    try {
      set(this.args.location, 'deviceNotificationsEnabled', false);
      yield this.args.location.save();
      this.isOpen = false;
      this.flashMessages.showAndHideFlash('success', 'iPad notifications disabled!');
      yield this.impressions.postImpression.perform(
        IMPRESSION_NAMES.VR_NOTIFICATIONS_DEVICE_IPAD_STATUS_EMAIL['DISABLED'],
      );
    } catch (e) {
      this.args.location.rollbackAttributes();
      this.isOpen = true;
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *saveTask() {
    try {
      yield this.args.location.save();
      yield this.args.location.pinDeviceContacts();
      this.args.onUpdate({ hasChanges: false });
      this.isOpen = false;
      this.impressions.postImpression.perform(IMPRESSION_NAMES.IPAD_ALERTS_CONFIG_UPDATED);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
      this.args.onUpdate({ hasChanges: true });
    }
  }

  @dropTask
  *editTask() {
    yield this.args.location.pinDeviceContacts();
    this.isOpen = true;
  }

  @restartableTask
  *searchTask(term) {
    yield timeout(zft(250));
    return yield this.args.searchUsersTask.perform(term, this.args.location.deviceContacts);
  }

  @restartableTask
  *updateDeviceContactsTask(users) {
    yield this.args.location.updateDeviceContacts(users);
    this.args.onUpdate({ hasChanges: true });
  }

  @action
  close() {
    if (this.args.deviceNotificationsHasChanges) {
      this.isShowingRollbackConfirmation = true;
    } else {
      this.isOpen = false;
    }
  }

  @action
  rollbackAndClose() {
    this.isShowingRollbackConfirmation = false;
    this.args.location.rollbackDeviceContacts();
    this.isOpen = false;
    this.args.onUpdate({ hasChanges: false });
  }
}
