import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import config from 'garaje/config/environment';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class EmployeesDirectoryAddEmployeeFromLocationController extends Controller {
  @service skinnyLocations;
  @service state;
  @service store;

  @controller('employees.directory') employeesController;

  queryParams = ['employeeId'];

  @tracked isDirty = false;
  @tracked employeeId = null;
  defaultCountry = config.defaultCountry;

  @action
  searchEmployees(term, includeLocations) {
    const params = {
      filter: {
        query: term,
        deleted: false,
        locations: this.state.currentLocation.id,
      },
    };

    if (includeLocations) {
      params.include = 'employee-locations,locations';
      params.filter.locations = this.skinnyLocations.currentCompanyLocations.map(({ id }) => id).join(',');
    }

    return this.store.query('employee', params);
  }

  @action
  async addEmployee(newEmployee) {
    const locations = await get(newEmployee, 'locations');
    locations.pushObject(this.state.currentLocation);
    return await newEmployee.save();
  }

  @task
  *afterAddEmployeeTask(employee) {
    yield this.employeesController.addEmployee(employee);
    yield this.state.checkRestrictionsTask.perform(true);
    this.transitionToRoute('employees.directory.employee', employee.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }
}
