import { Button, FormLayout, Modal, Stack, Label, TextField } from '@envoy/polarwind-react';
import {
  FormCheckbox,
  FormMultiselect,
  FormSelect,
  FormTextField,
  FormTextEditor,
} from '@envoy/react-rich-components-core';
import pluralize from 'pluralize';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { MESSAGE_AUDIENCE_TYPES } from '../../data/message-audience-types';
import { MESSAGE_DELIVERY_METHODS } from '../../data/message-delivery-methods';
import { useTemplateForm } from '../../hooks/use-template-form';
import { GQLAudiences } from '../../types/audience';
import { Location } from '../../types/location';
import { MessageCategory } from '../../types/message-category';
import { Template } from '../../types/template';

import './template-form.css';

export type TemplateFormDataAccess = {
  getAudiences: () => Promise<GQLAudiences>;
  getCategories: () => Promise<MessageCategory[]>;
  getLocations: () => Promise<Location[]>;
  getMessageVariables: () => Promise<{ label: string; value: string }[]>;
};

export type TemplateFormProps = {
  values?: Partial<Template>;
  dataAccess: TemplateFormDataAccess;
  templateId?: string;
  showEmployeeSelect: boolean;
  showVisitorSelect: boolean;
  canEditForm?: boolean;
  canMultiSelectAudiences?: boolean;
  isLoading?: boolean;
  onSave: (values: Partial<Template>) => Promise<void>;
  createCategory: (name: string) => Promise<MessageCategory | undefined>;
  onDelete?: () => Promise<void>;
};

export const TemplateForm: FC<TemplateFormProps> = (props) => {
  const form = useForm<Template>({
    defaultValues: {},
    mode: 'onChange',
  });

  const [message, locations] = form.watch(['message', 'locations']);

  const { values, isLoading, showEmployeeSelect, showVisitorSelect, canEditForm, canMultiSelectAudiences, onDelete } =
    props;

  const {
    categories,
    employeeAudienceOptions,
    visitorAudienceOptions,
    locationOptions,
    messageVariables,
    isDisabled,
    isSaving,
    isCreatingCategory,
    isDeleting,
    newCategoryName,
    showCreateCategoryModal,
    loadState,
    fillForm,
    handleDelete,
    handleSave,
    handleCreateCategory,
    setNewCategoryName,
    setShowCreateCategoryModal,
  } = useTemplateForm(props, form);

  useEffect(() => {
    void loadState();
  }, []);

  useEffect(() => {
    fillForm();
  }, [values]);

  useEffect(() => {
    setNewCategoryName('');
  }, [showCreateCategoryModal]);

  return (
    <FormProvider {...form}>
      <form className="react-form" onSubmit={(e) => void handleSave(e)} data-loading={isLoading}>
        <FormLayout>
          <div className="template-form-split-row">
            <FormTextField<Template>
              name="name"
              label="Template name"
              disabled={!canEditForm}
              placeholder="e.g. Flood Warning"
              required="Name field is required"
              testId="template-name"
            />

            <FormSelect<Template>
              name="category"
              label="Template category"
              required="Category field is required"
              disabled={!canEditForm}
              dropdownAction={{
                label: 'Create a category',
                onClick: () => setShowCreateCategoryModal(true),
              }}
              options={categories.map((c) => ({
                label: c.name,
                value: c.id,
              }))}
              preselectValue={false}
              placeholder="Select a category"
              testId="category-select"
            />
          </div>

          <FormTextField<Template>
            name="title"
            label="Message title (optional)"
            disabled={!canEditForm}
            placeholder="e.g. Urgent: Immediate Evacuation Required"
            tooltip="Message titles are not shown in SMS text messages"
            testId="template-title"
          />

          <div className="send-message__message">
            <FormTextEditor<Template>
              name="message"
              label="Message"
              required="Message field is required"
              disabled={!canEditForm}
              maxLength={{ value: 320, message: 'Reduce the length of your message' }}
              variables={messageVariables}
              multiline={6}
              placeholder="Write your message"
              key={values?.message}
              testId="template-message"
            />
            <div className="send-message__message-text">
              <div className="send-message__message-error">{form.formState.errors.message?.message}</div>
              <div className="send-message__message-length">{message?.length || 0}/320</div>
            </div>
          </div>

          <FormMultiselect<Template>
            name="defaultChannels"
            label="Delivery method(s)"
            required="You must select at least 1 delivery method"
            disabled={!canEditForm}
            options={MESSAGE_DELIVERY_METHODS}
            placeholder="Select how to deliver this message"
            testId="delivery-methods-select"
          />

          {showEmployeeSelect && canMultiSelectAudiences && (
            <FormMultiselect<Template>
              name="defaultEmployeeAudiences"
              label="Default employee group"
              disabled={!canEditForm}
              options={employeeAudienceOptions.map((a) => ({
                label: MESSAGE_AUDIENCE_TYPES[a.id].replace('%{location_name}', 'send location'),
                value: a.id,
              }))}
              placeholder="Select who should receive this message"
              testId="employee-group-multi-select"
            />
          )}

          {showEmployeeSelect && !canMultiSelectAudiences && (
            <FormSelect<Template>
              name="defaultEmployeeAudiences"
              label="Default employee group"
              disabled={!canEditForm}
              options={employeeAudienceOptions.map((a) => ({
                label: MESSAGE_AUDIENCE_TYPES[a.id].replace('%{location_name}', 'send location'),
                value: a.id,
              }))}
              preselectValue={false}
              placeholder="Select who should receive this message"
              testId="default-employee-group-select"
            />
          )}

          {showVisitorSelect && canMultiSelectAudiences && (
            <FormMultiselect<Template>
              name="defaultVisitorAudiences"
              label="Default visitor group"
              disabled={!canEditForm}
              options={visitorAudienceOptions.map((a) => ({
                label: MESSAGE_AUDIENCE_TYPES[a.id],
                value: a.id,
              }))}
              placeholder="Select who should receive this message"
              testId="visitor-group-multi-select"
            />
          )}

          {showVisitorSelect && !canMultiSelectAudiences && (
            <FormSelect<Template>
              name="defaultVisitorAudiences"
              label="Default visitor group"
              disabled={!canEditForm}
              options={visitorAudienceOptions.map((a) => ({
                label: MESSAGE_AUDIENCE_TYPES[a.id],
                value: a.id,
              }))}
              preselectValue={false}
              placeholder="Select who should receive this message"
              testId="include-visitors-select"
            />
          )}

          <FormCheckbox
            name="markAsSafe"
            label="Ask recipients to mark themselves as safe"
            disabled={!canEditForm}
            testId="mark-as-safe"
          />

          {!canEditForm && (
            <Label label="Locations applied">
              <Stack>
                {values?.locationNames?.map((name) => {
                  return (
                    <div className="location-pill" data-pill={name}>
                      {name}
                    </div>
                  );
                })}
              </Stack>
            </Label>
          )}

          {canEditForm && (
            <FormMultiselect<Template>
              name="locations"
              label="Locations applied"
              required="You must select at least one location to publish"
              disabled={!canEditForm}
              options={locationOptions.map((l) => ({
                label: l.name,
                value: l.id,
              }))}
              placeholder="Select the locations that this template applies to"
              testId="locations-select"
            />
          )}

          <div className="template-form-button-group">
            <Button type="submit" disabled={!canEditForm || isDisabled || isSaving || isDeleting} data-test-publish>
              Publish to {pluralize('location', locations?.length || 0, true)}
            </Button>

            {canEditForm && onDelete && (
              <Button
                outline
                type="button"
                disabled={isSaving || isDeleting}
                onClick={() => handleDelete()}
                data-test-delete
              >
                Delete
              </Button>
            )}
          </div>
        </FormLayout>
      </form>
      <Modal
        testId="create-category-modal"
        title="Create new category"
        primaryAction={() => handleCreateCategory()}
        primaryDisabled={isCreatingCategory || !newCategoryName.trim()}
        primaryText="Create"
        canClose={!isCreatingCategory}
        onClose={() => setShowCreateCategoryModal(false)}
        secondaryText="Cancel"
        showCloseButton={false}
        isOpen={showCreateCategoryModal}
      >
        <TextField
          value={newCategoryName}
          labelHidden
          placeholder="Enter category name"
          onChange={(value: string) => setNewCategoryName(value.trimStart() || '')}
          testId="category-name"
        />
      </Modal>
    </FormProvider>
  );
};
